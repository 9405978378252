export const pagesData = {
  // path: /
  "v-8daa1a0e": () => import(/* webpackChunkName: "v-8daa1a0e" */"/app/docs/.vuepress/.temp/pages/index.html.js").then(({ data }) => data),
  // path: /archive/C_sharp.html
  "v-3d00c951": () => import(/* webpackChunkName: "v-3d00c951" */"/app/docs/.vuepress/.temp/pages/archive/C_sharp.html.js").then(({ data }) => data),
  // path: /archive/android.html
  "v-15559070": () => import(/* webpackChunkName: "v-15559070" */"/app/docs/.vuepress/.temp/pages/archive/android.html.js").then(({ data }) => data),
  // path: /archive/ios.html
  "v-c59e76dc": () => import(/* webpackChunkName: "v-c59e76dc" */"/app/docs/.vuepress/.temp/pages/archive/ios.html.js").then(({ data }) => data),
  // path: /todo/collection-schemas.html
  "v-843656b4": () => import(/* webpackChunkName: "v-843656b4" */"/app/docs/.vuepress/.temp/pages/todo/collection-schemas.html.js").then(({ data }) => data),
  // path: /todo/feature-feautures.html
  "v-7c971834": () => import(/* webpackChunkName: "v-7c971834" */"/app/docs/.vuepress/.temp/pages/todo/feature-feautures.html.js").then(({ data }) => data),
  // path: /todo/governance-only.html
  "v-9311485c": () => import(/* webpackChunkName: "v-9311485c" */"/app/docs/.vuepress/.temp/pages/todo/governance-only.html.js").then(({ data }) => data),
  // path: /about/approach.html
  "v-02db6f96": () => import(/* webpackChunkName: "v-02db6f96" */"/app/docs/.vuepress/.temp/pages/about/approach.html.js").then(({ data }) => data),
  // path: /about/
  "v-74bc627b": () => import(/* webpackChunkName: "v-74bc627b" */"/app/docs/.vuepress/.temp/pages/about/index.html.js").then(({ data }) => data),
  // path: /about/networks.html
  "v-f864654e": () => import(/* webpackChunkName: "v-f864654e" */"/app/docs/.vuepress/.temp/pages/about/networks.html.js").then(({ data }) => data),
  // path: /about/timeline.html
  "v-1d52aa9d": () => import(/* webpackChunkName: "v-1d52aa9d" */"/app/docs/.vuepress/.temp/pages/about/timeline.html.js").then(({ data }) => data),
  // path: /build/
  "v-777a1bba": () => import(/* webpackChunkName: "v-777a1bba" */"/app/docs/.vuepress/.temp/pages/build/index.html.js").then(({ data }) => data),
  // path: /products/
  "v-807a9f1c": () => import(/* webpackChunkName: "v-807a9f1c" */"/app/docs/.vuepress/.temp/pages/products/index.html.js").then(({ data }) => data),
  // path: /reference/
  "v-22a2f9fd": () => import(/* webpackChunkName: "v-22a2f9fd" */"/app/docs/.vuepress/.temp/pages/reference/index.html.js").then(({ data }) => data),
  // path: /reference/sdk-endpoints.html
  "v-383a74cb": () => import(/* webpackChunkName: "v-383a74cb" */"/app/docs/.vuepress/.temp/pages/reference/sdk-endpoints.html.js").then(({ data }) => data),
  // path: /reference/sdk-methods.html
  "v-803929d2": () => import(/* webpackChunkName: "v-803929d2" */"/app/docs/.vuepress/.temp/pages/reference/sdk-methods.html.js").then(({ data }) => data),
  // path: /reference/tools.html
  "v-75b1c99b": () => import(/* webpackChunkName: "v-75b1c99b" */"/app/docs/.vuepress/.temp/pages/reference/tools.html.js").then(({ data }) => data),
  // path: /tutorials/
  "v-0efe29b3": () => import(/* webpackChunkName: "v-0efe29b3" */"/app/docs/.vuepress/.temp/pages/tutorials/index.html.js").then(({ data }) => data),
  // path: /tutorials/mass-transactions.html
  "v-4558477e": () => import(/* webpackChunkName: "v-4558477e" */"/app/docs/.vuepress/.temp/pages/tutorials/mass-transactions.html.js").then(({ data }) => data),
  // path: /tutorials/wallet-integration-old.html
  "v-901172a8": () => import(/* webpackChunkName: "v-901172a8" */"/app/docs/.vuepress/.temp/pages/tutorials/wallet-integration-old.html.js").then(({ data }) => data),
  // path: /todo/ecosystem/polkadot.html
  "v-5ed06074": () => import(/* webpackChunkName: "v-5ed06074" */"/app/docs/.vuepress/.temp/pages/todo/ecosystem/polkadot.html.js").then(({ data }) => data),
  // path: /todo/ecosystem/substrate.html
  "v-08bd9a92": () => import(/* webpackChunkName: "v-08bd9a92" */"/app/docs/.vuepress/.temp/pages/todo/ecosystem/substrate.html.js").then(({ data }) => data),
  // path: /todo/network-features/did.html
  "v-60c36fc6": () => import(/* webpackChunkName: "v-60c36fc6" */"/app/docs/.vuepress/.temp/pages/todo/network-features/did.html.js").then(({ data }) => data),
  // path: /todo/network-features/onchain-metadata.html
  "v-2866c13c": () => import(/* webpackChunkName: "v-2866c13c" */"/app/docs/.vuepress/.temp/pages/todo/network-features/onchain-metadata.html.js").then(({ data }) => data),
  // path: /todo/network-features/rate-limits.html
  "v-1f351892": () => import(/* webpackChunkName: "v-1f351892" */"/app/docs/.vuepress/.temp/pages/todo/network-features/rate-limits.html.js").then(({ data }) => data),
  // path: /todo/network-features/refungibility.html
  "v-21539485": () => import(/* webpackChunkName: "v-21539485" */"/app/docs/.vuepress/.temp/pages/todo/network-features/refungibility.html.js").then(({ data }) => data),
  // path: /todo/network-features/royalties.html
  "v-bb03485c": () => import(/* webpackChunkName: "v-bb03485c" */"/app/docs/.vuepress/.temp/pages/todo/network-features/royalties.html.js").then(({ data }) => data),
  // path: /todo/network-features/scheduling.html
  "v-904b03fc": () => import(/* webpackChunkName: "v-904b03fc" */"/app/docs/.vuepress/.temp/pages/todo/network-features/scheduling.html.js").then(({ data }) => data),
  // path: /todo/schemas/ERC721Metadata.html
  "v-438d0d86": () => import(/* webpackChunkName: "v-438d0d86" */"/app/docs/.vuepress/.temp/pages/todo/schemas/ERC721Metadata.html.js").then(({ data }) => data),
  // path: /todo/schemas/unique.html
  "v-ce97a68a": () => import(/* webpackChunkName: "v-ce97a68a" */"/app/docs/.vuepress/.temp/pages/todo/schemas/unique.html.js").then(({ data }) => data),
  // path: /about/network-features/evm.html
  "v-70956a15": () => import(/* webpackChunkName: "v-70956a15" */"/app/docs/.vuepress/.temp/pages/about/network-features/evm.html.js").then(({ data }) => data),
  // path: /about/network-features/marketplace.html
  "v-850b81f4": () => import(/* webpackChunkName: "v-850b81f4" */"/app/docs/.vuepress/.temp/pages/about/network-features/marketplace.html.js").then(({ data }) => data),
  // path: /about/network-features/pricing.html
  "v-17da2b2a": () => import(/* webpackChunkName: "v-17da2b2a" */"/app/docs/.vuepress/.temp/pages/about/network-features/pricing.html.js").then(({ data }) => data),
  // path: /about/network-features/sdk-indexer.html
  "v-18fc2785": () => import(/* webpackChunkName: "v-18fc2785" */"/app/docs/.vuepress/.temp/pages/about/network-features/sdk-indexer.html.js").then(({ data }) => data),
  // path: /about/network-features/sponsoring.html
  "v-d8d50a8e": () => import(/* webpackChunkName: "v-d8d50a8e" */"/app/docs/.vuepress/.temp/pages/about/network-features/sponsoring.html.js").then(({ data }) => data),
  // path: /about/network-features/staking.html
  "v-34c92f1a": () => import(/* webpackChunkName: "v-34c92f1a" */"/app/docs/.vuepress/.temp/pages/about/network-features/staking.html.js").then(({ data }) => data),
  // path: /about/nft-features/composable.html
  "v-1f52b5f4": () => import(/* webpackChunkName: "v-1f52b5f4" */"/app/docs/.vuepress/.temp/pages/about/nft-features/composable.html.js").then(({ data }) => data),
  // path: /about/nft-features/customizable.html
  "v-9d4cbf92": () => import(/* webpackChunkName: "v-9d4cbf92" */"/app/docs/.vuepress/.temp/pages/about/nft-features/customizable.html.js").then(({ data }) => data),
  // path: /about/nft-features/dynamic.html
  "v-319a1920": () => import(/* webpackChunkName: "v-319a1920" */"/app/docs/.vuepress/.temp/pages/about/nft-features/dynamic.html.js").then(({ data }) => data),
  // path: /about/nft-features/multimedia.html
  "v-32983e88": () => import(/* webpackChunkName: "v-32983e88" */"/app/docs/.vuepress/.temp/pages/about/nft-features/multimedia.html.js").then(({ data }) => data),
  // path: /about/nft-features/native.html
  "v-af6a7d88": () => import(/* webpackChunkName: "v-af6a7d88" */"/app/docs/.vuepress/.temp/pages/about/nft-features/native.html.js").then(({ data }) => data),
  // path: /about/nft-features/nested.html
  "v-524b8748": () => import(/* webpackChunkName: "v-524b8748" */"/app/docs/.vuepress/.temp/pages/about/nft-features/nested.html.js").then(({ data }) => data),
  // path: /about/nft-formats/dynamic-nfts.html
  "v-5c8fcf95": () => import(/* webpackChunkName: "v-5c8fcf95" */"/app/docs/.vuepress/.temp/pages/about/nft-formats/dynamic-nfts.html.js").then(({ data }) => data),
  // path: /about/token-types/coins.html
  "v-2b9a5a08": () => import(/* webpackChunkName: "v-2b9a5a08" */"/app/docs/.vuepress/.temp/pages/about/token-types/coins.html.js").then(({ data }) => data),
  // path: /about/token-types/main-types.html
  "v-e97a5262": () => import(/* webpackChunkName: "v-e97a5262" */"/app/docs/.vuepress/.temp/pages/about/token-types/main-types.html.js").then(({ data }) => data),
  // path: /about/token-types/nft.html
  "v-5482727c": () => import(/* webpackChunkName: "v-5482727c" */"/app/docs/.vuepress/.temp/pages/about/token-types/nft.html.js").then(({ data }) => data),
  // path: /about/token-types/rft.html
  "v-75437a3e": () => import(/* webpackChunkName: "v-75437a3e" */"/app/docs/.vuepress/.temp/pages/about/token-types/rft.html.js").then(({ data }) => data),
  // path: /build/evm/UniqueNFT.html
  "v-2944117b": () => import(/* webpackChunkName: "v-2944117b" */"/app/docs/.vuepress/.temp/pages/build/evm/UniqueNFT.html.js").then(({ data }) => data),
  // path: /build/evm/accounts.html
  "v-34149d74": () => import(/* webpackChunkName: "v-34149d74" */"/app/docs/.vuepress/.temp/pages/build/evm/accounts.html.js").then(({ data }) => data),
  // path: /build/evm/
  "v-9d3d3ee6": () => import(/* webpackChunkName: "v-9d3d3ee6" */"/app/docs/.vuepress/.temp/pages/build/evm/index.html.js").then(({ data }) => data),
  // path: /build/evm/todo-setting-project.html
  "v-171404f7": () => import(/* webpackChunkName: "v-171404f7" */"/app/docs/.vuepress/.temp/pages/build/evm/todo-setting-project.html.js").then(({ data }) => data),
  // path: /build/sdk/about_sdk.html
  "v-5408c100": () => import(/* webpackChunkName: "v-5408c100" */"/app/docs/.vuepress/.temp/pages/build/sdk/about_sdk.html.js").then(({ data }) => data),
  // path: /build/sdk/accounts.html
  "v-817131b8": () => import(/* webpackChunkName: "v-817131b8" */"/app/docs/.vuepress/.temp/pages/build/sdk/accounts.html.js").then(({ data }) => data),
  // path: /build/sdk/architecture.html
  "v-57971552": () => import(/* webpackChunkName: "v-57971552" */"/app/docs/.vuepress/.temp/pages/build/sdk/architecture.html.js").then(({ data }) => data),
  // path: /build/sdk/collections.html
  "v-61b60c53": () => import(/* webpackChunkName: "v-61b60c53" */"/app/docs/.vuepress/.temp/pages/build/sdk/collections.html.js").then(({ data }) => data),
  // path: /build/sdk/examples-nesting.html
  "v-49536104": () => import(/* webpackChunkName: "v-49536104" */"/app/docs/.vuepress/.temp/pages/build/sdk/examples-nesting.html.js").then(({ data }) => data),
  // path: /build/sdk/examplesCustomExtrinsic.html
  "v-e6af5aaa": () => import(/* webpackChunkName: "v-e6af5aaa" */"/app/docs/.vuepress/.temp/pages/build/sdk/examplesCustomExtrinsic.html.js").then(({ data }) => data),
  // path: /build/sdk/examplesLifeNFT.html
  "v-5d3b9f11": () => import(/* webpackChunkName: "v-5d3b9f11" */"/app/docs/.vuepress/.temp/pages/build/sdk/examplesLifeNFT.html.js").then(({ data }) => data),
  // path: /build/sdk/examplesSubstrateREST.html
  "v-dd9ff208": () => import(/* webpackChunkName: "v-dd9ff208" */"/app/docs/.vuepress/.temp/pages/build/sdk/examplesSubstrateREST.html.js").then(({ data }) => data),
  // path: /build/sdk/getting-started.html
  "v-0b7e2150": () => import(/* webpackChunkName: "v-0b7e2150" */"/app/docs/.vuepress/.temp/pages/build/sdk/getting-started.html.js").then(({ data }) => data),
  // path: /build/sdk/refungible.html
  "v-fcab5e46": () => import(/* webpackChunkName: "v-fcab5e46" */"/app/docs/.vuepress/.temp/pages/build/sdk/refungible.html.js").then(({ data }) => data),
  // path: /build/sdk/sponsoring.html
  "v-7b532cfc": () => import(/* webpackChunkName: "v-7b532cfc" */"/app/docs/.vuepress/.temp/pages/build/sdk/sponsoring.html.js").then(({ data }) => data),
  // path: /build/sdk/tokens.html
  "v-380a7630": () => import(/* webpackChunkName: "v-380a7630" */"/app/docs/.vuepress/.temp/pages/build/sdk/tokens.html.js").then(({ data }) => data),
  // path: /build/sdk/tools.html
  "v-d756f9e6": () => import(/* webpackChunkName: "v-d756f9e6" */"/app/docs/.vuepress/.temp/pages/build/sdk/tools.html.js").then(({ data }) => data),
  // path: /products/market/
  "v-b5b9827e": () => import(/* webpackChunkName: "v-b5b9827e" */"/app/docs/.vuepress/.temp/pages/products/market/index.html.js").then(({ data }) => data),
  // path: /products/minter/
  "v-9ad48664": () => import(/* webpackChunkName: "v-9ad48664" */"/app/docs/.vuepress/.temp/pages/products/minter/index.html.js").then(({ data }) => data),
  // path: /products/scan/
  "v-31125fc0": () => import(/* webpackChunkName: "v-31125fc0" */"/app/docs/.vuepress/.temp/pages/products/scan/index.html.js").then(({ data }) => data),
  // path: /products/wallet/
  "v-946512b8": () => import(/* webpackChunkName: "v-946512b8" */"/app/docs/.vuepress/.temp/pages/products/wallet/index.html.js").then(({ data }) => data),
  // path: /reference/blockchain/collections.html
  "v-669452a2": () => import(/* webpackChunkName: "v-669452a2" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/collections.html.js").then(({ data }) => data),
  // path: /reference/blockchain/contract-helpers.html
  "v-66883ad1": () => import(/* webpackChunkName: "v-66883ad1" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/contract-helpers.html.js").then(({ data }) => data),
  // path: /reference/blockchain/events.html
  "v-79df933c": () => import(/* webpackChunkName: "v-79df933c" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/events.html.js").then(({ data }) => data),
  // path: /reference/blockchain/extrinsics.html
  "v-4c646ebf": () => import(/* webpackChunkName: "v-4c646ebf" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/extrinsics.html.js").then(({ data }) => data),
  // path: /reference/blockchain/nesting.html
  "v-94f46c66": () => import(/* webpackChunkName: "v-94f46c66" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/nesting.html.js").then(({ data }) => data),
  // path: /reference/blockchain/owner-admin-roles.html
  "v-26093d12": () => import(/* webpackChunkName: "v-26093d12" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/owner-admin-roles.html.js").then(({ data }) => data),
  // path: /reference/blockchain/properties.html
  "v-bd5e8cf0": () => import(/* webpackChunkName: "v-bd5e8cf0" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/properties.html.js").then(({ data }) => data),
  // path: /reference/blockchain/rpc.html
  "v-f7ffe35c": () => import(/* webpackChunkName: "v-f7ffe35c" */"/app/docs/.vuepress/.temp/pages/reference/blockchain/rpc.html.js").then(({ data }) => data),
  // path: /reference/schemas/
  "v-4ea60c7a": () => import(/* webpackChunkName: "v-4ea60c7a" */"/app/docs/.vuepress/.temp/pages/reference/schemas/index.html.js").then(({ data }) => data),
  // path: /tutorials/evm/eth-general.html
  "v-5dd742ab": () => import(/* webpackChunkName: "v-5dd742ab" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/eth-general.html.js").then(({ data }) => data),
  // path: /tutorials/evm/how-to-ethereum.html
  "v-2b1cb35a": () => import(/* webpackChunkName: "v-2b1cb35a" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/how-to-ethereum.html.js").then(({ data }) => data),
  // path: /tutorials/evm/ready-samples.html
  "v-ef190240": () => import(/* webpackChunkName: "v-ef190240" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/ready-samples.html.js").then(({ data }) => data),
  // path: /tutorials/evm/using-contracts.html
  "v-57b98f47": () => import(/* webpackChunkName: "v-57b98f47" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/using-contracts.html.js").then(({ data }) => data),
  // path: /tutorials/evm/using-sol-interfaces.html
  "v-84d1531a": () => import(/* webpackChunkName: "v-84d1531a" */"/app/docs/.vuepress/.temp/pages/tutorials/evm/using-sol-interfaces.html.js").then(({ data }) => data),
  // path: /tutorials/minting/customizable-nfts.html
  "v-00fb0253": () => import(/* webpackChunkName: "v-00fb0253" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/customizable-nfts.html.js").then(({ data }) => data),
  // path: /tutorials/minting/generative-nft.html
  "v-980f3600": () => import(/* webpackChunkName: "v-980f3600" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/generative-nft.html.js").then(({ data }) => data),
  // path: /tutorials/minting/main.html
  "v-7fccee5e": () => import(/* webpackChunkName: "v-7fccee5e" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/main.html.js").then(({ data }) => data),
  // path: /tutorials/minting/mass-listing.html
  "v-1f180cac": () => import(/* webpackChunkName: "v-1f180cac" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/mass-listing.html.js").then(({ data }) => data),
  // path: /tutorials/minting/mass-minting.html
  "v-615a7c70": () => import(/* webpackChunkName: "v-615a7c70" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/mass-minting.html.js").then(({ data }) => data),
  // path: /tutorials/minting/prepare-account.html
  "v-67346f74": () => import(/* webpackChunkName: "v-67346f74" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/prepare-account.html.js").then(({ data }) => data),
  // path: /tutorials/minting/prepare-scripts.html
  "v-06a07d0e": () => import(/* webpackChunkName: "v-06a07d0e" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/prepare-scripts.html.js").then(({ data }) => data),
  // path: /tutorials/minting/setup-environment.html
  "v-0d4ca118": () => import(/* webpackChunkName: "v-0d4ca118" */"/app/docs/.vuepress/.temp/pages/tutorials/minting/setup-environment.html.js").then(({ data }) => data),
  // path: /tutorials/user-guides/ledger-connect.html
  "v-8a185bac": () => import(/* webpackChunkName: "v-8a185bac" */"/app/docs/.vuepress/.temp/pages/tutorials/user-guides/ledger-connect.html.js").then(({ data }) => data),
  // path: /tutorials/user-guides/polkadotjs.html
  "v-340ea201": () => import(/* webpackChunkName: "v-340ea201" */"/app/docs/.vuepress/.temp/pages/tutorials/user-guides/polkadotjs.html.js").then(({ data }) => data),
  // path: /tutorials/user-guides/wallets.html
  "v-58c12348": () => import(/* webpackChunkName: "v-58c12348" */"/app/docs/.vuepress/.temp/pages/tutorials/user-guides/wallets.html.js").then(({ data }) => data),
  // path: /build/evm/smart-contracts/collection-helpers.html
  "v-d1a0a4b8": () => import(/* webpackChunkName: "v-d1a0a4b8" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/collection-helpers.html.js").then(({ data }) => data),
  // path: /build/evm/smart-contracts/contract-helpers.html
  "v-6b79cbf0": () => import(/* webpackChunkName: "v-6b79cbf0" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/contract-helpers.html.js").then(({ data }) => data),
  // path: /build/evm/smart-contracts/
  "v-206d57bf": () => import(/* webpackChunkName: "v-206d57bf" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/index.html.js").then(({ data }) => data),
  // path: /build/evm/smart-contracts/writing-contracts.html
  "v-2f3fdb68": () => import(/* webpackChunkName: "v-2f3fdb68" */"/app/docs/.vuepress/.temp/pages/build/evm/smart-contracts/writing-contracts.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/balances.html
  "v-8c6ba084": () => import(/* webpackChunkName: "v-8c6ba084" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/balances.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/collections.html
  "v-73a45548": () => import(/* webpackChunkName: "v-73a45548" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/collections.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/dapps.html
  "v-ff8a44b2": () => import(/* webpackChunkName: "v-ff8a44b2" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/dapps.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/evm.html
  "v-093ea961": () => import(/* webpackChunkName: "v-093ea961" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/evm.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/indexer.html
  "v-9a8453c4": () => import(/* webpackChunkName: "v-9a8453c4" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/indexer.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/migration.html
  "v-0b8f2baf": () => import(/* webpackChunkName: "v-0b8f2baf" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/migration.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/quick-start.html
  "v-341f2e7b": () => import(/* webpackChunkName: "v-341f2e7b" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/quick-start.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/sponsoring.html
  "v-489ea426": () => import(/* webpackChunkName: "v-489ea426" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/sponsoring.html.js").then(({ data }) => data),
  // path: /build/sdk/v2/tokens.html
  "v-3f75a81b": () => import(/* webpackChunkName: "v-3f75a81b" */"/app/docs/.vuepress/.temp/pages/build/sdk/v2/tokens.html.js").then(({ data }) => data),
  // path: /build/tech-concepts/addresses/
  "v-8cfdeb58": () => import(/* webpackChunkName: "v-8cfdeb58" */"/app/docs/.vuepress/.temp/pages/build/tech-concepts/addresses/index.html.js").then(({ data }) => data),
  // path: /build/tech-concepts/balances/
  "v-481a5791": () => import(/* webpackChunkName: "v-481a5791" */"/app/docs/.vuepress/.temp/pages/build/tech-concepts/balances/index.html.js").then(({ data }) => data),
  // path: /404.html
  "v-3706649a": () => import(/* webpackChunkName: "v-3706649a" */"/app/docs/.vuepress/.temp/pages/404.html.js").then(({ data }) => data),
}
