export const searchIndex = [
  {
    "title": "",
    "headers": [],
    "path": "/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "C# SDK",
    "headers": [
      {
        "level": 2,
        "title": "Unique SKD C#",
        "slug": "unique-skd-c",
        "link": "#unique-skd-c",
        "children": []
      },
      {
        "level": 2,
        "title": "How to install Unique SDK C#",
        "slug": "how-to-install-unique-sdk-c",
        "link": "#how-to-install-unique-sdk-c",
        "children": []
      },
      {
        "level": 2,
        "title": "Usage examples",
        "slug": "usage-examples",
        "link": "#usage-examples",
        "children": [
          {
            "level": 3,
            "title": "Signer creation",
            "slug": "signer-creation",
            "link": "#signer-creation",
            "children": []
          },
          {
            "level": 3,
            "title": "Creation of a collection",
            "slug": "creation-of-a-collection",
            "link": "#creation-of-a-collection",
            "children": []
          }
        ]
      }
    ],
    "path": "/archive/C_sharp.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Kotlin SDK (Android)",
    "headers": [
      {
        "level": 2,
        "title": "Unique Kotlin SDK",
        "slug": "unique-kotlin-sdk",
        "link": "#unique-kotlin-sdk",
        "children": []
      },
      {
        "level": 2,
        "title": "How to install Unique Kotlin SDK",
        "slug": "how-to-install-unique-kotlin-sdk",
        "link": "#how-to-install-unique-kotlin-sdk",
        "children": []
      },
      {
        "level": 2,
        "title": "Usage Examples",
        "slug": "usage-examples",
        "link": "#usage-examples",
        "children": [
          {
            "level": 3,
            "title": "Signer creation",
            "slug": "signer-creation",
            "link": "#signer-creation",
            "children": []
          },
          {
            "level": 3,
            "title": "Creation of a collection",
            "slug": "creation-of-a-collection",
            "link": "#creation-of-a-collection",
            "children": []
          }
        ]
      }
    ],
    "path": "/archive/android.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "iOS UniqueSDK",
    "headers": [
      {
        "level": 2,
        "title": "Overview",
        "slug": "overview",
        "link": "#overview",
        "children": []
      },
      {
        "level": 2,
        "title": "How to install iOS UniqueSDK",
        "slug": "how-to-install-ios-uniquesdk",
        "link": "#how-to-install-ios-uniquesdk",
        "children": []
      },
      {
        "level": 2,
        "title": "Usage Examples",
        "slug": "usage-examples",
        "link": "#usage-examples",
        "children": [
          {
            "level": 3,
            "title": "Chain configuration",
            "slug": "chain-configuration",
            "link": "#chain-configuration",
            "children": []
          },
          {
            "level": 3,
            "title": "Creation of a user account",
            "slug": "creation-of-a-user-account",
            "link": "#creation-of-a-user-account",
            "children": []
          },
          {
            "level": 3,
            "title": "Creation of a collection",
            "slug": "creation-of-a-collection",
            "link": "#creation-of-a-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Creation of a token",
            "slug": "creation-of-a-token",
            "link": "#creation-of-a-token",
            "children": []
          },
          {
            "level": 3,
            "title": "Creation of a  collection with attributes",
            "slug": "creation-of-a-collection-with-attributes",
            "link": "#creation-of-a-collection-with-attributes",
            "children": []
          },
          {
            "level": 3,
            "title": "Creation of a token with attributes",
            "slug": "creation-of-a-token-with-attributes",
            "link": "#creation-of-a-token-with-attributes",
            "children": []
          },
          {
            "level": 3,
            "title": "Transfer token",
            "slug": "transfer-token",
            "link": "#transfer-token",
            "children": []
          },
          {
            "level": 3,
            "title": "Transfer balance",
            "slug": "transfer-balance",
            "link": "#transfer-balance",
            "children": []
          }
        ]
      }
    ],
    "path": "/archive/ios.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Collection schemas",
    "headers": [],
    "path": "/todo/collection-schemas.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "",
    "headers": [],
    "path": "/todo/feature-feautures.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Governance-only Calls",
    "headers": [],
    "path": "/todo/governance-only.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "NFT 2.0 approach",
    "headers": [
      {
        "level": 2,
        "title": "\"NFT is a How, not a What\"",
        "slug": "nft-is-a-how-not-a-what",
        "link": "#nft-is-a-how-not-a-what",
        "children": []
      },
      {
        "level": 2,
        "title": "Unique Network NFT 2.0 Features",
        "slug": "unique-network-nft-2-0-features",
        "link": "#unique-network-nft-2-0-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Just a few Use Cases for NFTs 2.0",
        "slug": "just-a-few-use-cases-for-nfts-2-0",
        "link": "#just-a-few-use-cases-for-nfts-2-0",
        "children": []
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/approach.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "About",
    "headers": [
      {
        "level": 2,
        "title": "What is Unique Network?",
        "slug": "what-is-unique-network",
        "link": "#what-is-unique-network",
        "children": []
      },
      {
        "level": 2,
        "title": "Our Mission",
        "slug": "our-mission",
        "link": "#our-mission",
        "children": []
      },
      {
        "level": 2,
        "title": "Key Features",
        "slug": "key-features",
        "link": "#key-features",
        "children": [
          {
            "level": 3,
            "title": "🎨 Highly Customizable NFTs",
            "slug": "🎨-highly-customizable-nfts",
            "link": "#🎨-highly-customizable-nfts",
            "children": []
          },
          {
            "level": 3,
            "title": "🛠️ User-Friendly Tools",
            "slug": "🛠️-user-friendly-tools",
            "link": "#🛠️-user-friendly-tools",
            "children": []
          },
          {
            "level": 3,
            "title": "🔗 Interoperability",
            "slug": "🔗-interoperability",
            "link": "#🔗-interoperability",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Why Choose Unique Network?",
        "slug": "why-choose-unique-network",
        "link": "#why-choose-unique-network",
        "children": []
      },
      {
        "level": 2,
        "title": "Join Unique Network Community",
        "slug": "join-unique-network-community",
        "link": "#join-unique-network-community",
        "children": []
      },
      {
        "level": 2,
        "title": "Build with us!",
        "slug": "build-with-us",
        "link": "#build-with-us",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Networks",
    "headers": [
      {
        "level": 2,
        "title": "Unique Network (Polkadot Parachain)",
        "slug": "unique-network-polkadot-parachain",
        "link": "#unique-network-polkadot-parachain",
        "children": []
      },
      {
        "level": 2,
        "title": "Quartz (Kusama Parachain)",
        "slug": "quartz-kusama-parachain",
        "link": "#quartz-kusama-parachain",
        "children": []
      },
      {
        "level": 2,
        "title": "Opal (Testnet)",
        "slug": "opal-testnet",
        "link": "#opal-testnet",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/networks.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Timeline",
    "headers": [
      {
        "level": 2,
        "title": "2020: Establishing a Strong Foundation",
        "slug": "_2020-establishing-a-strong-foundation",
        "link": "#_2020-establishing-a-strong-foundation",
        "children": []
      },
      {
        "level": 2,
        "title": "2021: Accelerating Growth and Engagement",
        "slug": "_2021-accelerating-growth-and-engagement",
        "link": "#_2021-accelerating-growth-and-engagement",
        "children": []
      },
      {
        "level": 2,
        "title": "2022: Expanding Reach and Capabilities",
        "slug": "_2022-expanding-reach-and-capabilities",
        "link": "#_2022-expanding-reach-and-capabilities",
        "children": []
      },
      {
        "level": 2,
        "title": "2023: Broadening Horizons",
        "slug": "_2023-broadening-horizons",
        "link": "#_2023-broadening-horizons",
        "children": []
      },
      {
        "level": 2,
        "title": "2024: The Future Look Ahead",
        "slug": "_2024-the-future-look-ahead",
        "link": "#_2024-the-future-look-ahead",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/timeline.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Welcome to the building section",
    "headers": [],
    "path": "/build/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Products",
    "headers": [],
    "path": "/products/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Networks list",
    "headers": [
      {
        "level": 2,
        "title": "Unique - Polkadot parachain",
        "slug": "unique-polkadot-parachain",
        "link": "#unique-polkadot-parachain",
        "children": []
      },
      {
        "level": 2,
        "title": "Quartz - Kusama parachain",
        "slug": "quartz-kusama-parachain",
        "link": "#quartz-kusama-parachain",
        "children": []
      },
      {
        "level": 2,
        "title": "Opal - testnet",
        "slug": "opal-testnet",
        "link": "#opal-testnet",
        "children": []
      }
    ],
    "path": "/reference/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Public endpoints",
    "headers": [
      {
        "level": 2,
        "title": "SDK-V2",
        "slug": "sdk-v2",
        "link": "#sdk-v2",
        "children": []
      },
      {
        "level": 2,
        "title": "SDK-V1",
        "slug": "sdk-v1",
        "link": "#sdk-v1",
        "children": []
      }
    ],
    "path": "/reference/sdk-endpoints.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Methods",
    "headers": [
      {
        "level": 2,
        "title": "Collection",
        "slug": "collection",
        "link": "#collection",
        "children": []
      },
      {
        "level": 2,
        "title": "Collections admin",
        "slug": "collections-admin",
        "link": "#collections-admin",
        "children": []
      },
      {
        "level": 2,
        "title": "Nesting",
        "slug": "nesting",
        "link": "#nesting",
        "children": []
      },
      {
        "level": 2,
        "title": "Sponsorship",
        "slug": "sponsorship",
        "link": "#sponsorship",
        "children": []
      },
      {
        "level": 2,
        "title": "Statistics",
        "slug": "statistics",
        "link": "#statistics",
        "children": []
      },
      {
        "level": 2,
        "title": "Token",
        "slug": "token",
        "link": "#token",
        "children": []
      },
      {
        "level": 2,
        "title": "Returns",
        "slug": "returns-38",
        "link": "#returns-38",
        "children": []
      },
      {
        "level": 2,
        "title": "Examples",
        "slug": "examples-39",
        "link": "#examples-39",
        "children": []
      },
      {
        "level": 2,
        "title": "Other",
        "slug": "other",
        "link": "#other",
        "children": []
      },
      {
        "level": 2,
        "title": "Balance",
        "slug": "balance",
        "link": "#balance",
        "children": []
      },
      {
        "level": 2,
        "title": "Fungible",
        "slug": "fungible",
        "link": "#fungible",
        "children": []
      },
      {
        "level": 2,
        "title": "Returns",
        "slug": "returns-53",
        "link": "#returns-53",
        "children": []
      },
      {
        "level": 2,
        "title": "Examples",
        "slug": "examples-54",
        "link": "#examples-54",
        "children": []
      },
      {
        "level": 2,
        "title": "Refungible",
        "slug": "refungible",
        "link": "#refungible",
        "children": []
      },
      {
        "level": 2,
        "title": "Evm",
        "slug": "evm",
        "link": "#evm",
        "children": []
      },
      {
        "level": 2,
        "title": "Common",
        "slug": "common",
        "link": "#common",
        "children": []
      },
      {
        "level": 2,
        "title": "Ipfs",
        "slug": "ipfs",
        "link": "#ipfs",
        "children": [
          {
            "level": 3,
            "title": "Upload file",
            "slug": "upload-file",
            "link": "#upload-file",
            "children": []
          },
          {
            "level": 3,
            "title": "Upload multiple files",
            "slug": "upload-multiple-files",
            "link": "#upload-multiple-files",
            "children": []
          },
          {
            "level": 3,
            "title": "Add multiple files to existing folder",
            "slug": "add-multiple-files-to-existing-folder",
            "link": "#add-multiple-files-to-existing-folder",
            "children": []
          }
        ]
      }
    ],
    "path": "/reference/sdk-methods.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Tools",
    "headers": [
      {
        "level": 3,
        "title": "Live address encoder",
        "slug": "live-address-encoder",
        "link": "#live-address-encoder",
        "children": []
      },
      {
        "level": 3,
        "title": "Collection address encoder",
        "slug": "collection-address-encoder",
        "link": "#collection-address-encoder",
        "children": []
      }
    ],
    "path": "/reference/tools.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Welcome to the tutorials section",
    "headers": [],
    "path": "/tutorials/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Bulk Transactions",
    "headers": [
      {
        "level": 3,
        "title": "What happens when transactions from a single account are executed simultaneously?",
        "slug": "what-happens-when-transactions-from-a-single-account-are-executed-simultaneously",
        "link": "#what-happens-when-transactions-from-a-single-account-are-executed-simultaneously",
        "children": []
      },
      {
        "level": 3,
        "title": "Transaction Nonce",
        "slug": "transaction-nonce",
        "link": "#transaction-nonce",
        "children": []
      },
      {
        "level": 3,
        "title": "Alternative methods",
        "slug": "alternative-methods",
        "link": "#alternative-methods",
        "children": []
      }
    ],
    "path": "/tutorials/mass-transactions.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Wallet integration",
    "headers": [],
    "path": "/tutorials/wallet-integration-old.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Polkadot and Kusama",
    "headers": [
      {
        "level": 2,
        "title": "Polkadot",
        "slug": "polkadot",
        "link": "#polkadot",
        "children": []
      },
      {
        "level": 2,
        "title": "Kusama",
        "slug": "kusama",
        "link": "#kusama",
        "children": []
      }
    ],
    "path": "/todo/ecosystem/polkadot.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Substrate",
    "headers": [],
    "path": "/todo/ecosystem/substrate.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Decentralized IDs",
    "headers": [],
    "path": "/todo/network-features/did.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Onchain metadata",
    "headers": [],
    "path": "/todo/network-features/onchain-metadata.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Rate limits",
    "headers": [],
    "path": "/todo/network-features/rate-limits.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Refungibility",
    "headers": [],
    "path": "/todo/network-features/refungibility.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Royalties",
    "headers": [],
    "path": "/todo/network-features/royalties.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Scheduling",
    "headers": [],
    "path": "/todo/network-features/scheduling.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "ERC721Metadata schema",
    "headers": [],
    "path": "/todo/schemas/ERC721Metadata.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique Schema 1.0.0",
    "headers": [
      {
        "level": 3,
        "title": "Collection properties should have:",
        "slug": "collection-properties-should-have",
        "link": "#collection-properties-should-have",
        "children": []
      },
      {
        "level": 3,
        "title": "Token properties should have:",
        "slug": "token-properties-should-have",
        "link": "#token-properties-should-have",
        "children": []
      },
      {
        "level": 2,
        "title": "Media",
        "slug": "media",
        "link": "#media",
        "children": [
          {
            "level": 3,
            "title": "Infix",
            "slug": "infix",
            "link": "#infix",
            "children": []
          },
          {
            "level": 3,
            "title": "Url",
            "slug": "url",
            "link": "#url",
            "children": []
          },
          {
            "level": 3,
            "title": "IpfsCid",
            "slug": "ipfscid",
            "link": "#ipfscid",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Attributes",
        "slug": "attributes",
        "link": "#attributes",
        "children": [
          {
            "level": 3,
            "title": "Schema",
            "slug": "schema",
            "link": "#schema",
            "children": []
          }
        ]
      }
    ],
    "path": "/todo/schemas/unique.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "EVM and Smart Contracts",
    "headers": [
      {
        "level": 2,
        "title": "EVM Smart Contracts",
        "slug": "evm-smart-contracts",
        "link": "#evm-smart-contracts",
        "children": []
      },
      {
        "level": 2,
        "title": "Interoperability with Substrate and Ethereum APIs",
        "slug": "interoperability-with-substrate-and-ethereum-apis",
        "link": "#interoperability-with-substrate-and-ethereum-apis",
        "children": []
      },
      {
        "level": 2,
        "title": "Benefits for Developers",
        "slug": "benefits-for-developers",
        "link": "#benefits-for-developers",
        "children": []
      },
      {
        "level": 2,
        "title": "Learn More",
        "slug": "learn-more",
        "link": "#learn-more",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/network-features/evm.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique Network Marketplace",
    "headers": [
      {
        "level": 2,
        "title": "Key Features",
        "slug": "key-features",
        "link": "#key-features",
        "children": [
          {
            "level": 3,
            "title": "Trading Platform",
            "slug": "trading-platform",
            "link": "#trading-platform",
            "children": []
          },
          {
            "level": 3,
            "title": "UI Minting",
            "slug": "ui-minting",
            "link": "#ui-minting",
            "children": []
          },
          {
            "level": 3,
            "title": "Testing Ground for Features and Tools",
            "slug": "testing-ground-for-features-and-tools",
            "link": "#testing-ground-for-features-and-tools",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Get Started",
        "slug": "get-started",
        "link": "#get-started",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/network-features/marketplace.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Pricing",
    "headers": [
      {
        "level": 2,
        "title": "Optimized NFT Actions",
        "slug": "optimized-nft-actions",
        "link": "#optimized-nft-actions",
        "children": []
      },
      {
        "level": 2,
        "title": "Networks and Native Tokens",
        "slug": "networks-and-native-tokens",
        "link": "#networks-and-native-tokens",
        "children": []
      }
    ],
    "path": "/about/network-features/pricing.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "SDK and Indexer",
    "headers": [
      {
        "level": 2,
        "title": "SDK",
        "slug": "sdk",
        "link": "#sdk",
        "children": []
      },
      {
        "level": 2,
        "title": "Indexer",
        "slug": "indexer",
        "link": "#indexer",
        "children": []
      },
      {
        "level": 2,
        "title": "Learn More",
        "slug": "learn-more",
        "link": "#learn-more",
        "children": []
      },
      {
        "level": 2,
        "title": "Build with us!",
        "slug": "build-with-us",
        "link": "#build-with-us",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/network-features/sdk-indexer.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Sponsoring",
    "headers": [
      {
        "level": 2,
        "title": "Key Benefits of Sponsoring",
        "slug": "key-benefits-of-sponsoring",
        "link": "#key-benefits-of-sponsoring",
        "children": []
      },
      {
        "level": 2,
        "title": "Types of Sponsored Transactions",
        "slug": "types-of-sponsored-transactions",
        "link": "#types-of-sponsored-transactions",
        "children": []
      },
      {
        "level": 2,
        "title": "Preventing Attacks",
        "slug": "preventing-attacks",
        "link": "#preventing-attacks",
        "children": []
      },
      {
        "level": 2,
        "title": "Learn More",
        "slug": "learn-more",
        "link": "#learn-more",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/network-features/sponsoring.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Staking",
    "headers": [
      {
        "level": 2,
        "title": "Staking on Unique Network",
        "slug": "staking-on-unique-network",
        "link": "#staking-on-unique-network",
        "children": [
          {
            "level": 3,
            "title": "Key Benefits:",
            "slug": "key-benefits",
            "link": "#key-benefits",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "How to Stake",
        "slug": "how-to-stake",
        "link": "#how-to-stake",
        "children": [
          {
            "level": 3,
            "title": "Step-by-Step Guide:",
            "slug": "step-by-step-guide",
            "link": "#step-by-step-guide",
            "children": []
          },
          {
            "level": 3,
            "title": "Important Considerations:",
            "slug": "important-considerations",
            "link": "#important-considerations",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Networks and Native Tokens",
        "slug": "networks-and-native-tokens",
        "link": "#networks-and-native-tokens",
        "children": []
      }
    ],
    "path": "/about/network-features/staking.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Composable NFTs",
    "headers": [
      {
        "level": 2,
        "title": "To put it simply",
        "slug": "to-put-it-simply",
        "link": "#to-put-it-simply",
        "children": []
      },
      {
        "level": 2,
        "title": "Key Features",
        "slug": "key-features",
        "link": "#key-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Use Case Examples",
        "slug": "use-case-examples",
        "link": "#use-case-examples",
        "children": [
          {
            "level": 3,
            "title": "🎮 Gaming",
            "slug": "🎮-gaming",
            "link": "#🎮-gaming",
            "children": []
          },
          {
            "level": 3,
            "title": "👗 Fashion & Retail",
            "slug": "👗-fashion-retail",
            "link": "#👗-fashion-retail",
            "children": []
          },
          {
            "level": 3,
            "title": "🎵 Music & Entertainment",
            "slug": "🎵-music-entertainment",
            "link": "#🎵-music-entertainment",
            "children": []
          },
          {
            "level": 3,
            "title": "🖼️ Art & Digital Collectibles",
            "slug": "🖼️-art-digital-collectibles",
            "link": "#🖼️-art-digital-collectibles",
            "children": []
          },
          {
            "level": 3,
            "title": "🏠 Real Estate & Virtual Spaces",
            "slug": "🏠-real-estate-virtual-spaces",
            "link": "#🏠-real-estate-virtual-spaces",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/nft-features/composable.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Customizable NFTs",
    "headers": [
      {
        "level": 2,
        "title": "To put it simply",
        "slug": "to-put-it-simply",
        "link": "#to-put-it-simply",
        "children": []
      },
      {
        "level": 2,
        "title": "Key Features",
        "slug": "key-features",
        "link": "#key-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Use Case Examples",
        "slug": "use-case-examples",
        "link": "#use-case-examples",
        "children": [
          {
            "level": 3,
            "title": "🎮 Gaming",
            "slug": "🎮-gaming",
            "link": "#🎮-gaming",
            "children": []
          },
          {
            "level": 3,
            "title": "👗 Fashion & Retail",
            "slug": "👗-fashion-retail",
            "link": "#👗-fashion-retail",
            "children": []
          },
          {
            "level": 3,
            "title": "🎵 Music & Entertainment",
            "slug": "🎵-music-entertainment",
            "link": "#🎵-music-entertainment",
            "children": []
          },
          {
            "level": 3,
            "title": "🖼️ Art & Digital Collectibles",
            "slug": "🖼️-art-digital-collectibles",
            "link": "#🖼️-art-digital-collectibles",
            "children": []
          },
          {
            "level": 3,
            "title": "🏠 Real Estate & Virtual Spaces",
            "slug": "🏠-real-estate-virtual-spaces",
            "link": "#🏠-real-estate-virtual-spaces",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/nft-features/customizable.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Dynamic NFTs",
    "headers": [
      {
        "level": 2,
        "title": "To put it simply",
        "slug": "to-put-it-simply",
        "link": "#to-put-it-simply",
        "children": []
      },
      {
        "level": 2,
        "title": "Key Features",
        "slug": "key-features",
        "link": "#key-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Benefits for Users",
        "slug": "benefits-for-users",
        "link": "#benefits-for-users",
        "children": []
      },
      {
        "level": 2,
        "title": "Use Case Examples",
        "slug": "use-case-examples",
        "link": "#use-case-examples",
        "children": [
          {
            "level": 3,
            "title": "🎮 Gaming: Evolving Characters",
            "slug": "🎮-gaming-evolving-characters",
            "link": "#🎮-gaming-evolving-characters",
            "children": []
          },
          {
            "level": 3,
            "title": "🖼️ Digital Art: Interactive Artwork",
            "slug": "🖼️-digital-art-interactive-artwork",
            "link": "#🖼️-digital-art-interactive-artwork",
            "children": []
          },
          {
            "level": 3,
            "title": "🧸 Collectibles: Digital Pets",
            "slug": "🧸-collectibles-digital-pets",
            "link": "#🧸-collectibles-digital-pets",
            "children": []
          },
          {
            "level": 3,
            "title": "💹 Financial Instruments: Real-Time Updates",
            "slug": "💹-financial-instruments-real-time-updates",
            "link": "#💹-financial-instruments-real-time-updates",
            "children": []
          },
          {
            "level": 3,
            "title": "🎵 Music and Entertainment: Interactive Experiences",
            "slug": "🎵-music-and-entertainment-interactive-experiences",
            "link": "#🎵-music-and-entertainment-interactive-experiences",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/nft-features/dynamic.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Multimedia NFTs",
    "headers": [
      {
        "level": 2,
        "title": "Key Features",
        "slug": "key-features",
        "link": "#key-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Use Cases",
        "slug": "use-cases",
        "link": "#use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/nft-features/multimedia.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Native NFTs",
    "headers": [
      {
        "level": 2,
        "title": "How Unique Native NFTs differs from Smart Contract NFTs (Old Approach)",
        "slug": "how-unique-native-nfts-differs-from-smart-contract-nfts-old-approach",
        "link": "#how-unique-native-nfts-differs-from-smart-contract-nfts-old-approach",
        "children": [
          {
            "level": 3,
            "title": "Native NFTs (Our Approach)",
            "slug": "native-nfts-our-approach",
            "link": "#native-nfts-our-approach",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Native NFTs Key Features",
        "slug": "native-nfts-key-features",
        "link": "#native-nfts-key-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/nft-features/native.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Nested NFTs",
    "headers": [
      {
        "level": 2,
        "title": "To put it simply",
        "slug": "to-put-it-simply",
        "link": "#to-put-it-simply",
        "children": []
      },
      {
        "level": 2,
        "title": "Key Features",
        "slug": "key-features",
        "link": "#key-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Benefits for Users",
        "slug": "benefits-for-users",
        "link": "#benefits-for-users",
        "children": []
      },
      {
        "level": 2,
        "title": "Use Case Examples",
        "slug": "use-case-examples",
        "link": "#use-case-examples",
        "children": [
          {
            "level": 3,
            "title": "📖 Digital Storytelling",
            "slug": "📖-digital-storytelling",
            "link": "#📖-digital-storytelling",
            "children": []
          },
          {
            "level": 3,
            "title": "🎨 Art Installations",
            "slug": "🎨-art-installations",
            "link": "#🎨-art-installations",
            "children": []
          },
          {
            "level": 3,
            "title": "🎮 Gaming: In-game Assets",
            "slug": "🎮-gaming-in-game-assets",
            "link": "#🎮-gaming-in-game-assets",
            "children": []
          },
          {
            "level": 3,
            "title": "👗 Fashion & Retail: Virtual Showrooms",
            "slug": "👗-fashion-retail-virtual-showrooms",
            "link": "#👗-fashion-retail-virtual-showrooms",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/nft-features/nested.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "",
    "headers": [],
    "path": "/about/nft-formats/dynamic-nfts.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Coins",
    "headers": [
      {
        "level": 2,
        "title": "Native Coins",
        "slug": "native-coins",
        "link": "#native-coins",
        "children": [
          {
            "level": 3,
            "title": "Definition",
            "slug": "definition",
            "link": "#definition",
            "children": []
          },
          {
            "level": 3,
            "title": "Characteristics",
            "slug": "characteristics",
            "link": "#characteristics",
            "children": []
          },
          {
            "level": 3,
            "title": "Native Coins Examples",
            "slug": "native-coins-examples",
            "link": "#native-coins-examples",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Non-Native Coins",
        "slug": "non-native-coins",
        "link": "#non-native-coins",
        "children": [
          {
            "level": 3,
            "title": "Definition",
            "slug": "definition-1",
            "link": "#definition-1",
            "children": []
          },
          {
            "level": 3,
            "title": "Characteristics",
            "slug": "characteristics-1",
            "link": "#characteristics-1",
            "children": []
          },
          {
            "level": 3,
            "title": "ERC-20 Tokens",
            "slug": "erc-20-tokens",
            "link": "#erc-20-tokens",
            "children": []
          },
          {
            "level": 3,
            "title": "Examples of ERC-20 Tokens",
            "slug": "examples-of-erc-20-tokens",
            "link": "#examples-of-erc-20-tokens",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Unique Network and ERC-20 Support",
        "slug": "unique-network-and-erc-20-support",
        "link": "#unique-network-and-erc-20-support",
        "children": [
          {
            "level": 3,
            "title": "Use Cases for ERC-20 Support",
            "slug": "use-cases-for-erc-20-support",
            "link": "#use-cases-for-erc-20-support",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/token-types/coins.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Overview",
    "headers": [
      {
        "level": 2,
        "title": "Non-Fungible Tokens (NFTs)",
        "slug": "non-fungible-tokens-nfts",
        "link": "#non-fungible-tokens-nfts",
        "children": [
          {
            "level": 3,
            "title": "Definition",
            "slug": "definition",
            "link": "#definition",
            "children": []
          },
          {
            "level": 3,
            "title": "Characteristics",
            "slug": "characteristics",
            "link": "#characteristics",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Unique Network NFT 2.0 Features",
        "slug": "unique-network-nft-2-0-features",
        "link": "#unique-network-nft-2-0-features",
        "children": []
      },
      {
        "level": 2,
        "title": "Fractional NFTs (RFTs)",
        "slug": "fractional-nfts-rfts",
        "link": "#fractional-nfts-rfts",
        "children": [
          {
            "level": 3,
            "title": "Definition",
            "slug": "definition-1",
            "link": "#definition-1",
            "children": []
          },
          {
            "level": 3,
            "title": "Characteristics",
            "slug": "characteristics-1",
            "link": "#characteristics-1",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Coins",
        "slug": "coins",
        "link": "#coins",
        "children": [
          {
            "level": 3,
            "title": "Definition",
            "slug": "definition-2",
            "link": "#definition-2",
            "children": []
          },
          {
            "level": 3,
            "title": "Characteristics",
            "slug": "characteristics-2",
            "link": "#characteristics-2",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/token-types/main-types.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "NFTs",
    "headers": [
      {
        "level": 2,
        "title": "Advanced NFT features overview",
        "slug": "advanced-nft-features-overview",
        "link": "#advanced-nft-features-overview",
        "children": []
      },
      {
        "level": 2,
        "title": "1. Nested NFTs",
        "slug": "_1-nested-nfts",
        "link": "#_1-nested-nfts",
        "children": []
      },
      {
        "level": 2,
        "title": "2. Dynamic NFTs",
        "slug": "_2-dynamic-nfts",
        "link": "#_2-dynamic-nfts",
        "children": []
      },
      {
        "level": 2,
        "title": "3. Customizable NFTs",
        "slug": "_3-customizable-nfts",
        "link": "#_3-customizable-nfts",
        "children": []
      },
      {
        "level": 2,
        "title": "4. Composable NFTs",
        "slug": "_4-composable-nfts",
        "link": "#_4-composable-nfts",
        "children": []
      },
      {
        "level": 2,
        "title": "Ask AI for Tailored Use cases",
        "slug": "ask-ai-for-tailored-use-cases",
        "link": "#ask-ai-for-tailored-use-cases",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/token-types/nft.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Fractional NFTs",
    "headers": [
      {
        "level": 2,
        "title": "Overview",
        "slug": "overview",
        "link": "#overview",
        "children": []
      },
      {
        "level": 2,
        "title": "How Does it Work?",
        "slug": "how-does-it-work",
        "link": "#how-does-it-work",
        "children": []
      },
      {
        "level": 2,
        "title": "Creating and Managing Fractional NFTs",
        "slug": "creating-and-managing-fractional-nfts",
        "link": "#creating-and-managing-fractional-nfts",
        "children": []
      },
      {
        "level": 2,
        "title": "Related Articles",
        "slug": "related-articles",
        "link": "#related-articles",
        "children": []
      }
    ],
    "path": "/about/token-types/rft.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "UniqueNFT",
    "headers": [
      {
        "level": 3,
        "title": "Collection Id to Ethereum contract address conversion",
        "slug": "collection-id-to-ethereum-contract-address-conversion",
        "link": "#collection-id-to-ethereum-contract-address-conversion",
        "children": []
      },
      {
        "level": 3,
        "title": "Ethereum contract address to Collection Id",
        "slug": "ethereum-contract-address-to-collection-id",
        "link": "#ethereum-contract-address-to-collection-id",
        "children": []
      },
      {
        "level": 3,
        "title": "Live converter",
        "slug": "live-converter",
        "link": "#live-converter",
        "children": []
      }
    ],
    "path": "/build/evm/UniqueNFT.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Account",
    "headers": [
      {
        "level": 3,
        "title": "Account mirrors",
        "slug": "account-mirrors",
        "link": "#account-mirrors",
        "children": []
      },
      {
        "level": 3,
        "title": "Using CrossAddress",
        "slug": "using-crossaddress",
        "link": "#using-crossaddress",
        "children": []
      },
      {
        "level": 2,
        "title": "Code example",
        "slug": "code-example",
        "link": "#code-example",
        "children": []
      }
    ],
    "path": "/build/evm/accounts.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Intro to Unique EVM",
    "headers": [
      {
        "level": 2,
        "title": "Navigation",
        "slug": "navigation",
        "link": "#navigation",
        "children": []
      },
      {
        "level": 2,
        "title": "Connect to the EVM on Unique Network",
        "slug": "connect-to-the-evm-on-unique-network",
        "link": "#connect-to-the-evm-on-unique-network",
        "children": []
      }
    ],
    "path": "/build/evm/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "",
    "headers": [],
    "path": "/build/evm/todo-setting-project.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "What is the SDK (or Unique Network connection tools)",
    "headers": [
      {
        "level": 2,
        "title": "The SDK is out! Meet the amazing new developer tool",
        "slug": "the-sdk-is-out-meet-the-amazing-new-developer-tool",
        "link": "#the-sdk-is-out-meet-the-amazing-new-developer-tool",
        "children": []
      },
      {
        "level": 2,
        "title": "Substrate-first / Ethereum-first",
        "slug": "substrate-first-ethereum-first",
        "link": "#substrate-first-ethereum-first",
        "children": []
      },
      {
        "level": 2,
        "title": "A Software Development Kit to the rescue",
        "slug": "a-software-development-kit-to-the-rescue",
        "link": "#a-software-development-kit-to-the-rescue",
        "children": []
      },
      {
        "level": 2,
        "title": "What's planned for the nearest future",
        "slug": "what-s-planned-for-the-nearest-future",
        "link": "#what-s-planned-for-the-nearest-future",
        "children": []
      },
      {
        "level": 2,
        "title": "TL;DR",
        "slug": "tl-dr",
        "link": "#tl-dr",
        "children": []
      }
    ],
    "path": "/build/sdk/about_sdk.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Working with accounts",
    "headers": [
      {
        "level": 2,
        "title": "Creating a Substrate address with wallets",
        "slug": "creating-a-substrate-address-with-wallets",
        "link": "#creating-a-substrate-address-with-wallets",
        "children": []
      },
      {
        "level": 2,
        "title": "Create an account via SDK",
        "slug": "create-an-account-via-sdk",
        "link": "#create-an-account-via-sdk",
        "children": [
          {
            "level": 3,
            "title": "Generate a new account",
            "slug": "generate-a-new-account",
            "link": "#generate-a-new-account",
            "children": []
          },
          {
            "level": 3,
            "title": "Providers",
            "slug": "providers",
            "link": "#providers",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Get and display accounts and balances",
        "slug": "get-and-display-accounts-and-balances",
        "link": "#get-and-display-accounts-and-balances",
        "children": [
          {
            "level": 3,
            "title": "Get accounts",
            "slug": "get-accounts",
            "link": "#get-accounts",
            "children": []
          },
          {
            "level": 3,
            "title": "Get balances",
            "slug": "get-balances",
            "link": "#get-balances",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Create an account via web form",
        "slug": "create-an-account-via-web-form",
        "link": "#create-an-account-via-web-form",
        "children": []
      }
    ],
    "path": "/build/sdk/accounts.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Process layout",
    "headers": [
      {
        "level": 2,
        "title": "Extrinsic",
        "slug": "extrinsic",
        "link": "#extrinsic",
        "children": []
      },
      {
        "level": 2,
        "title": "Extrinsic lifecycle in Unique Network connection tools",
        "slug": "extrinsic-lifecycle-in-unique-network-connection-tools",
        "link": "#extrinsic-lifecycle-in-unique-network-connection-tools",
        "children": []
      },
      {
        "level": 2,
        "title": "Input and output data",
        "slug": "input-and-output-data",
        "link": "#input-and-output-data",
        "children": []
      },
      {
        "level": 2,
        "title": "Common errors",
        "slug": "common-errors",
        "link": "#common-errors",
        "children": []
      }
    ],
    "path": "/build/sdk/architecture.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Working with collections",
    "headers": [
      {
        "level": 2,
        "title": "Intro",
        "slug": "intro",
        "link": "#intro",
        "children": []
      },
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Collection creation",
        "slug": "collection-creation",
        "link": "#collection-creation",
        "children": [
          {
            "level": 3,
            "title": "Create a collection",
            "slug": "create-a-collection",
            "link": "#create-a-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Create a collection using schemas",
            "slug": "create-a-collection-using-schemas",
            "link": "#create-a-collection-using-schemas",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Get a collection",
        "slug": "get-a-collection",
        "link": "#get-a-collection",
        "children": []
      },
      {
        "level": 2,
        "title": "Collection settings",
        "slug": "collection-settings",
        "link": "#collection-settings",
        "children": [
          {
            "level": 3,
            "title": "Set collection limits",
            "slug": "set-collection-limits",
            "link": "#set-collection-limits",
            "children": []
          },
          {
            "level": 3,
            "title": "Change the owner of the collection",
            "slug": "change-the-owner-of-the-collection",
            "link": "#change-the-owner-of-the-collection",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Properties",
        "slug": "properties",
        "link": "#properties",
        "children": [
          {
            "level": 3,
            "title": "Get collection properties",
            "slug": "get-collection-properties",
            "link": "#get-collection-properties",
            "children": []
          },
          {
            "level": 3,
            "title": "Set collection properties",
            "slug": "set-collection-properties",
            "link": "#set-collection-properties",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Admins",
        "slug": "admins",
        "link": "#admins",
        "children": [
          {
            "level": 3,
            "title": "Get admin list",
            "slug": "get-admin-list",
            "link": "#get-admin-list",
            "children": []
          },
          {
            "level": 3,
            "title": "Add a collection admin",
            "slug": "add-a-collection-admin",
            "link": "#add-a-collection-admin",
            "children": []
          },
          {
            "level": 3,
            "title": "Remove a collection admin",
            "slug": "remove-a-collection-admin",
            "link": "#remove-a-collection-admin",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Destroy a collection",
        "slug": "destroy-a-collection",
        "link": "#destroy-a-collection",
        "children": []
      }
    ],
    "path": "/build/sdk/collections.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Nesting",
    "headers": [
      {
        "level": 2,
        "title": "Nesting permission",
        "slug": "nesting-permission",
        "link": "#nesting-permission",
        "children": []
      },
      {
        "level": 2,
        "title": "Creating bundles",
        "slug": "creating-bundles",
        "link": "#creating-bundles",
        "children": []
      },
      {
        "level": 2,
        "title": "Check if the token is bundle",
        "slug": "check-if-the-token-is-bundle",
        "link": "#check-if-the-token-is-bundle",
        "children": []
      },
      {
        "level": 2,
        "title": "Unnest",
        "slug": "unnest",
        "link": "#unnest",
        "children": []
      }
    ],
    "path": "/build/sdk/examples-nesting.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Universal call of chain methods via SDK",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Chain state, RPC call, Extrinsic in Polkadot GUI",
        "slug": "chain-state-rpc-call-extrinsic-in-polkadot-gui",
        "link": "#chain-state-rpc-call-extrinsic-in-polkadot-gui",
        "children": []
      },
      {
        "level": 2,
        "title": "List methods in SDK",
        "slug": "list-methods-in-sdk",
        "link": "#list-methods-in-sdk",
        "children": []
      },
      {
        "level": 2,
        "title": "Query a Chain state or an RPC call via the SDK",
        "slug": "query-a-chain-state-or-an-rpc-call-via-the-sdk",
        "link": "#query-a-chain-state-or-an-rpc-call-via-the-sdk",
        "children": []
      },
      {
        "level": 2,
        "title": "Executing an extrinsic via the SDK",
        "slug": "executing-an-extrinsic-via-the-sdk",
        "link": "#executing-an-extrinsic-via-the-sdk",
        "children": []
      },
      {
        "level": 2,
        "title": "Example types in Polkadot GUI",
        "slug": "example-types-in-polkadot-gui",
        "link": "#example-types-in-polkadot-gui",
        "children": []
      }
    ],
    "path": "/build/sdk/examplesCustomExtrinsic.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Live NFT",
    "headers": [],
    "path": "/build/sdk/examplesLifeNFT.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "REST API examples",
    "headers": [
      {
        "level": 2,
        "title": "Pure REST (curl) example",
        "slug": "pure-rest-curl-example",
        "link": "#pure-rest-curl-example",
        "children": []
      },
      {
        "level": 2,
        "title": "Programming language example",
        "slug": "programming-language-example",
        "link": "#programming-language-example",
        "children": []
      }
    ],
    "path": "/build/sdk/examplesSubstrateREST.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Getting started",
    "headers": [
      {
        "level": 2,
        "title": "Overview",
        "slug": "overview",
        "link": "#overview",
        "children": []
      },
      {
        "level": 2,
        "title": "Installation",
        "slug": "installation",
        "link": "#installation",
        "children": []
      },
      {
        "level": 2,
        "title": "Creating SDK instance",
        "slug": "creating-sdk-instance",
        "link": "#creating-sdk-instance",
        "children": []
      },
      {
        "level": 2,
        "title": "Creating accounts",
        "slug": "creating-accounts",
        "link": "#creating-accounts",
        "children": [
          {
            "level": 3,
            "title": "Get an account from the mnemonic phrase",
            "slug": "get-an-account-from-the-mnemonic-phrase",
            "link": "#get-an-account-from-the-mnemonic-phrase",
            "children": []
          },
          {
            "level": 3,
            "title": "Providers",
            "slug": "providers",
            "link": "#providers",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Creating collections and NFTs",
        "slug": "creating-collections-and-nfts",
        "link": "#creating-collections-and-nfts",
        "children": []
      },
      {
        "level": 2,
        "title": "Transfer NFT",
        "slug": "transfer-nft",
        "link": "#transfer-nft",
        "children": []
      },
      {
        "level": 2,
        "title": "Batch several transactions",
        "slug": "batch-several-transactions",
        "link": "#batch-several-transactions",
        "children": []
      }
    ],
    "path": "/build/sdk/getting-started.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Re-Fungible",
    "headers": [],
    "path": "/build/sdk/refungible.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Transaction sponsoring",
    "headers": [
      {
        "level": 2,
        "title": "Collection sponsoring",
        "slug": "collection-sponsoring",
        "link": "#collection-sponsoring",
        "children": []
      },
      {
        "level": 2,
        "title": "Contract sponsoring",
        "slug": "contract-sponsoring",
        "link": "#contract-sponsoring",
        "children": []
      }
    ],
    "path": "/build/sdk/sponsoring.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Working with tokens",
    "headers": [
      {
        "level": 2,
        "title": "Intro",
        "slug": "intro",
        "link": "#intro",
        "children": []
      },
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Mint token",
        "slug": "mint-token",
        "link": "#mint-token",
        "children": []
      },
      {
        "level": 2,
        "title": "Bulk mint",
        "slug": "bulk-mint",
        "link": "#bulk-mint",
        "children": []
      },
      {
        "level": 2,
        "title": "Set token properties",
        "slug": "set-token-properties",
        "link": "#set-token-properties",
        "children": []
      },
      {
        "level": 2,
        "title": "Get token properties",
        "slug": "get-token-properties",
        "link": "#get-token-properties",
        "children": []
      },
      {
        "level": 2,
        "title": "Delete token properties",
        "slug": "delete-token-properties",
        "link": "#delete-token-properties",
        "children": []
      },
      {
        "level": 2,
        "title": "Get token",
        "slug": "get-token",
        "link": "#get-token",
        "children": []
      },
      {
        "level": 2,
        "title": "Transfer token",
        "slug": "transfer-token",
        "link": "#transfer-token",
        "children": []
      },
      {
        "level": 2,
        "title": "Burn token",
        "slug": "burn-token",
        "link": "#burn-token",
        "children": []
      }
    ],
    "path": "/build/sdk/tokens.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Tools",
    "headers": [
      {
        "level": 3,
        "title": "Accounts",
        "slug": "accounts",
        "link": "#accounts",
        "children": []
      },
      {
        "level": 3,
        "title": "Providers",
        "slug": "providers",
        "link": "#providers",
        "children": []
      }
    ],
    "path": "/build/sdk/tools.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Market",
    "headers": [
      {
        "level": 2,
        "title": "Overview",
        "slug": "overview",
        "link": "#overview",
        "children": []
      },
      {
        "level": 2,
        "title": "Viewing Tokens",
        "slug": "viewing-tokens",
        "link": "#viewing-tokens",
        "children": []
      },
      {
        "level": 2,
        "title": "Purchasing an NFT",
        "slug": "purchasing-an-nft",
        "link": "#purchasing-an-nft",
        "children": []
      },
      {
        "level": 2,
        "title": "Selling an NFT on the Market",
        "slug": "selling-an-nft-on-the-market",
        "link": "#selling-an-nft-on-the-market",
        "children": []
      },
      {
        "level": 2,
        "title": "Removing tokens from the sale",
        "slug": "removing-tokens-from-the-sale",
        "link": "#removing-tokens-from-the-sale",
        "children": []
      },
      {
        "level": 2,
        "title": "Transferring tokens",
        "slug": "transferring-tokens",
        "link": "#transferring-tokens",
        "children": []
      },
      {
        "level": 2,
        "title": "Build a proof of concept Marketplace",
        "slug": "build-a-proof-of-concept-marketplace",
        "link": "#build-a-proof-of-concept-marketplace",
        "children": []
      }
    ],
    "path": "/products/market/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Minter",
    "headers": [],
    "path": "/products/minter/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Scan",
    "headers": [],
    "path": "/products/scan/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Wallet",
    "headers": [],
    "path": "/products/wallet/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Collection data",
    "headers": [
      {
        "level": 2,
        "title": "name, description, tokenPrefix",
        "slug": "name-description-tokenprefix",
        "link": "#name-description-tokenprefix",
        "children": []
      },
      {
        "level": 2,
        "title": "mode",
        "slug": "mode",
        "link": "#mode",
        "children": []
      },
      {
        "level": 2,
        "title": "limits",
        "slug": "limits",
        "link": "#limits",
        "children": []
      },
      {
        "level": 2,
        "title": "permissions",
        "slug": "permissions",
        "link": "#permissions",
        "children": []
      },
      {
        "level": 2,
        "title": "tokenPropertyPermissions",
        "slug": "tokenpropertypermissions",
        "link": "#tokenpropertypermissions",
        "children": []
      },
      {
        "level": 2,
        "title": "properties",
        "slug": "properties",
        "link": "#properties",
        "children": []
      },
      {
        "level": 2,
        "title": "adminList",
        "slug": "adminlist",
        "link": "#adminlist",
        "children": []
      }
    ],
    "path": "/reference/blockchain/collections.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Contract Helpers",
    "headers": [
      {
        "level": 2,
        "title": "Interface",
        "slug": "interface",
        "link": "#interface",
        "children": []
      }
    ],
    "path": "/reference/blockchain/contract-helpers.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique Pallet - Events",
    "headers": [],
    "path": "/reference/blockchain/events.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique Pallet - Extrinsics",
    "headers": [
      {
        "level": 3,
        "title": "🔸addCollectionAdmin",
        "slug": "addcollectionadmin",
        "link": "#addcollectionadmin",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸addToAllowList",
        "slug": "addtoallowlist",
        "link": "#addtoallowlist",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸approve",
        "slug": "approve",
        "link": "#approve",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸burnFrom",
        "slug": "burnfrom",
        "link": "#burnfrom",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸burnItem",
        "slug": "burnitem",
        "link": "#burnitem",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸changeCollectionOwner",
        "slug": "changecollectionowner",
        "link": "#changecollectionowner",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸confirmSponsorship",
        "slug": "confirmsponsorship",
        "link": "#confirmsponsorship",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸createCollection",
        "slug": "createcollection",
        "link": "#createcollection",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸createCollectionEx",
        "slug": "createcollectionex",
        "link": "#createcollectionex",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸createItem",
        "slug": "createitem",
        "link": "#createitem",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸createMultipleItems",
        "slug": "createmultipleitems",
        "link": "#createmultipleitems",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸createMultipleItemsEx",
        "slug": "createmultipleitemsex",
        "link": "#createmultipleitemsex",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸deleteCollectionProperties",
        "slug": "deletecollectionproperties",
        "link": "#deletecollectionproperties",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸deleteTokenProperties",
        "slug": "deletetokenproperties",
        "link": "#deletetokenproperties",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸destroyCollection",
        "slug": "destroycollection",
        "link": "#destroycollection",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸removeCollectionAdmin",
        "slug": "removecollectionadmin",
        "link": "#removecollectionadmin",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸removeCollectionSponsor",
        "slug": "removecollectionsponsor",
        "link": "#removecollectionsponsor",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸removeFromAllowList",
        "slug": "removefromallowlist",
        "link": "#removefromallowlist",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸setCollectionLimits",
        "slug": "setcollectionlimits",
        "link": "#setcollectionlimits",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸setCollectionPermissions",
        "slug": "setcollectionpermissions",
        "link": "#setcollectionpermissions",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸setCollectionProperties",
        "slug": "setcollectionproperties",
        "link": "#setcollectionproperties",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸setCollectionSponsor",
        "slug": "setcollectionsponsor",
        "link": "#setcollectionsponsor",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸setTransfersEnabledFlag",
        "slug": "settransfersenabledflag",
        "link": "#settransfersenabledflag",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸setTokenProperties",
        "slug": "settokenproperties",
        "link": "#settokenproperties",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸setPropertyPermissions",
        "slug": "setpropertypermissions",
        "link": "#setpropertypermissions",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸transfer",
        "slug": "transfer",
        "link": "#transfer",
        "children": []
      },
      {
        "level": 3,
        "title": "🔸transferFrom",
        "slug": "transferfrom",
        "link": "#transferfrom",
        "children": []
      }
    ],
    "path": "/reference/blockchain/extrinsics.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Nesting",
    "headers": [
      {
        "level": 3,
        "title": "Nesting settings",
        "slug": "nesting-settings",
        "link": "#nesting-settings",
        "children": []
      },
      {
        "level": 2,
        "title": "RPC Methods",
        "slug": "rpc-methods",
        "link": "#rpc-methods",
        "children": [
          {
            "level": 3,
            "title": "🔶 topmostTokenOwner",
            "slug": "🔶-topmosttokenowner",
            "link": "#🔶-topmosttokenowner",
            "children": []
          },
          {
            "level": 3,
            "title": "🔶 tokenChildren",
            "slug": "🔶-tokenchildren",
            "link": "#🔶-tokenchildren",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Notes",
        "slug": "notes",
        "link": "#notes",
        "children": []
      }
    ],
    "path": "/reference/blockchain/nesting.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Roles and access rights",
    "headers": [
      {
        "level": 2,
        "title": "Collection admins and collection owners",
        "slug": "collection-admins-and-collection-owners",
        "link": "#collection-admins-and-collection-owners",
        "children": []
      },
      {
        "level": 2,
        "title": "Token owners",
        "slug": "token-owners",
        "link": "#token-owners",
        "children": []
      }
    ],
    "path": "/reference/blockchain/owner-admin-roles.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Collection and Token Properties",
    "headers": [
      {
        "level": 2,
        "title": "RPC Methods",
        "slug": "rpc-methods",
        "link": "#rpc-methods",
        "children": [
          {
            "level": 3,
            "title": "🔶 collectionProperties",
            "slug": "🔶-collectionproperties",
            "link": "#🔶-collectionproperties",
            "children": []
          },
          {
            "level": 3,
            "title": "🔶 propertyPermissions",
            "slug": "🔶-propertypermissions",
            "link": "#🔶-propertypermissions",
            "children": []
          },
          {
            "level": 3,
            "title": "🔶 tokenData",
            "slug": "🔶-tokendata",
            "link": "#🔶-tokendata",
            "children": []
          },
          {
            "level": 3,
            "title": "🔶 tokenProperties",
            "slug": "🔶-tokenproperties",
            "link": "#🔶-tokenproperties",
            "children": []
          }
        ]
      }
    ],
    "path": "/reference/blockchain/properties.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique Pallet - RPC calls",
    "headers": [
      {
        "level": 3,
        "title": "🔹accountBalance",
        "slug": "accountbalance",
        "link": "#accountbalance",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹accountTokens",
        "slug": "accounttokens",
        "link": "#accounttokens",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹adminlist",
        "slug": "adminlist",
        "link": "#adminlist",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹allowance",
        "slug": "allowance",
        "link": "#allowance",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹allowed",
        "slug": "allowed",
        "link": "#allowed",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹allowlist",
        "slug": "allowlist",
        "link": "#allowlist",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹balance",
        "slug": "balance",
        "link": "#balance",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹collectionById",
        "slug": "collectionbyid",
        "link": "#collectionbyid",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹collectionStats",
        "slug": "collectionstats",
        "link": "#collectionstats",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹collectionTokens",
        "slug": "collectiontokens",
        "link": "#collectiontokens",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹effectiveCollectionLimits",
        "slug": "effectivecollectionlimits",
        "link": "#effectivecollectionlimits",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹lastTokenId",
        "slug": "lasttokenid",
        "link": "#lasttokenid",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹nextSponsored",
        "slug": "nextsponsored",
        "link": "#nextsponsored",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹tokenExists",
        "slug": "tokenexists",
        "link": "#tokenexists",
        "children": []
      },
      {
        "level": 3,
        "title": "🔹tokenOwner",
        "slug": "tokenowner",
        "link": "#tokenowner",
        "children": []
      }
    ],
    "path": "/reference/blockchain/rpc.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique Schema",
    "headers": [
      {
        "level": 2,
        "title": "Overview",
        "slug": "overview",
        "link": "#overview",
        "children": []
      },
      {
        "level": 2,
        "title": "Unique Metadata Schema V2",
        "slug": "unique-metadata-schema-v2",
        "link": "#unique-metadata-schema-v2",
        "children": []
      },
      {
        "level": 2,
        "title": "NFT Token Schema V2 Detailed Description",
        "slug": "nft-token-schema-v2-detailed-description",
        "link": "#nft-token-schema-v2-detailed-description",
        "children": [
          {
            "level": 3,
            "title": "Common fields",
            "slug": "common-fields",
            "link": "#common-fields",
            "children": []
          },
          {
            "level": 3,
            "title": "Media field",
            "slug": "media-field",
            "link": "#media-field",
            "children": []
          },
          {
            "level": 3,
            "title": "Royalties field",
            "slug": "royalties-field",
            "link": "#royalties-field",
            "children": []
          },
          {
            "level": 3,
            "title": "Customizable NFT Fields",
            "slug": "customizable-nft-fields",
            "link": "#customizable-nft-fields",
            "children": []
          },
          {
            "level": 3,
            "title": "Customizing Overrides",
            "slug": "customizing-overrides",
            "link": "#customizing-overrides",
            "children": []
          },
          {
            "level": 3,
            "title": "Optional fields",
            "slug": "optional-fields",
            "link": "#optional-fields",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "NFT Collection Schema V2",
        "slug": "nft-collection-schema-v2",
        "link": "#nft-collection-schema-v2",
        "children": []
      },
      {
        "level": 2,
        "title": "Types",
        "slug": "types",
        "link": "#types",
        "children": []
      }
    ],
    "path": "/reference/schemas/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "General info",
    "headers": [
      {
        "level": 2,
        "title": "Intro",
        "slug": "intro",
        "link": "#intro",
        "children": []
      },
      {
        "level": 2,
        "title": "Prerequisites",
        "slug": "prerequisites",
        "link": "#prerequisites",
        "children": []
      },
      {
        "level": 2,
        "title": "Set up environment",
        "slug": "set-up-environment",
        "link": "#set-up-environment",
        "children": [
          {
            "level": 3,
            "title": "Install and initialize the libraries",
            "slug": "install-and-initialize-the-libraries",
            "link": "#install-and-initialize-the-libraries",
            "children": []
          },
          {
            "level": 3,
            "title": "Connect to network and Metamask account",
            "slug": "connect-to-network-and-metamask-account",
            "link": "#connect-to-network-and-metamask-account",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Write new smart contract",
        "slug": "write-new-smart-contract",
        "link": "#write-new-smart-contract",
        "children": []
      },
      {
        "level": 2,
        "title": "Deploy smart contract",
        "slug": "deploy-smart-contract",
        "link": "#deploy-smart-contract",
        "children": []
      },
      {
        "level": 2,
        "title": "Cross address",
        "slug": "cross-address",
        "link": "#cross-address",
        "children": []
      },
      {
        "level": 2,
        "title": "URI and URISuffix",
        "slug": "uri-and-urisuffix",
        "link": "#uri-and-urisuffix",
        "children": []
      }
    ],
    "path": "/tutorials/evm/eth-general.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "How to work with Unique Network using Ethereum technologies",
    "headers": [
      {
        "level": 2,
        "title": "Intro",
        "slug": "intro",
        "link": "#intro",
        "children": []
      },
      {
        "level": 2,
        "title": "Using your own smart contract",
        "slug": "using-your-own-smart-contract",
        "link": "#using-your-own-smart-contract",
        "children": [
          {
            "level": 3,
            "title": "Create NFT collection",
            "slug": "create-nft-collection",
            "link": "#create-nft-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Set sponsor for smart contract",
            "slug": "set-sponsor-for-smart-contract",
            "link": "#set-sponsor-for-smart-contract",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Using Unique solidity interfaces",
        "slug": "using-unique-solidity-interfaces",
        "link": "#using-unique-solidity-interfaces",
        "children": [
          {
            "level": 3,
            "title": "Create NFT collection",
            "slug": "create-nft-collection-1",
            "link": "#create-nft-collection-1",
            "children": []
          },
          {
            "level": 3,
            "title": "Make collection 721Metadata compatible",
            "slug": "make-collection-721metadata-compatible",
            "link": "#make-collection-721metadata-compatible",
            "children": []
          },
          {
            "level": 3,
            "title": "Set sponsor for collection",
            "slug": "set-sponsor-for-collection",
            "link": "#set-sponsor-for-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT token",
            "slug": "mint-nft-token",
            "link": "#mint-nft-token",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT token with cross address",
            "slug": "mint-nft-token-with-cross-address",
            "link": "#mint-nft-token-with-cross-address",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT with token URI",
            "slug": "mint-nft-with-token-uri",
            "link": "#mint-nft-with-token-uri",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT with a token suffix",
            "slug": "mint-nft-with-a-token-suffix",
            "link": "#mint-nft-with-a-token-suffix",
            "children": []
          },
          {
            "level": 3,
            "title": "Transfer token",
            "slug": "transfer-token",
            "link": "#transfer-token",
            "children": []
          },
          {
            "level": 3,
            "title": "Create RFT collection",
            "slug": "create-rft-collection",
            "link": "#create-rft-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint refungible token",
            "slug": "mint-refungible-token",
            "link": "#mint-refungible-token",
            "children": []
          },
          {
            "level": 3,
            "title": "Create fungible collection",
            "slug": "create-fungible-collection",
            "link": "#create-fungible-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint fungible token",
            "slug": "mint-fungible-token",
            "link": "#mint-fungible-token",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Results",
        "slug": "results",
        "link": "#results",
        "children": [
          {
            "level": 3,
            "title": "Mint NFT - Full Example",
            "slug": "mint-nft-full-example",
            "link": "#mint-nft-full-example",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint RFT - Full Example",
            "slug": "mint-rft-full-example",
            "link": "#mint-rft-full-example",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint Fungible - Full Example",
            "slug": "mint-fungible-full-example",
            "link": "#mint-fungible-full-example",
            "children": []
          }
        ]
      }
    ],
    "path": "/tutorials/evm/how-to-ethereum.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Ready to use samples",
    "headers": [
      {
        "level": 3,
        "title": "Mint NFT",
        "slug": "mint-nft",
        "link": "#mint-nft",
        "children": []
      },
      {
        "level": 3,
        "title": "Mint RFT",
        "slug": "mint-rft",
        "link": "#mint-rft",
        "children": []
      },
      {
        "level": 3,
        "title": "Mint Fungible",
        "slug": "mint-fungible",
        "link": "#mint-fungible",
        "children": []
      }
    ],
    "path": "/tutorials/evm/ready-samples.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Using your own smart contract",
    "headers": [
      {
        "level": 3,
        "title": "Create NFT collection",
        "slug": "create-nft-collection",
        "link": "#create-nft-collection",
        "children": []
      },
      {
        "level": 3,
        "title": "Set sponsor for smart contract",
        "slug": "set-sponsor-for-smart-contract",
        "link": "#set-sponsor-for-smart-contract",
        "children": []
      }
    ],
    "path": "/tutorials/evm/using-contracts.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Using Solidity interfaces library",
    "headers": [
      {
        "level": 2,
        "title": "Intro",
        "slug": "intro",
        "link": "#intro",
        "children": []
      },
      {
        "level": 2,
        "title": "NFT",
        "slug": "nft",
        "link": "#nft",
        "children": [
          {
            "level": 3,
            "title": "Create NFT collection",
            "slug": "create-nft-collection",
            "link": "#create-nft-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT token",
            "slug": "mint-nft-token",
            "link": "#mint-nft-token",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT token with cross address",
            "slug": "mint-nft-token-with-cross-address",
            "link": "#mint-nft-token-with-cross-address",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT with token URI",
            "slug": "mint-nft-with-token-uri",
            "link": "#mint-nft-with-token-uri",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint NFT with a token suffix",
            "slug": "mint-nft-with-a-token-suffix",
            "link": "#mint-nft-with-a-token-suffix",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "RFT",
        "slug": "rft",
        "link": "#rft",
        "children": [
          {
            "level": 3,
            "title": "Create RFT collection",
            "slug": "create-rft-collection",
            "link": "#create-rft-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint refungible token",
            "slug": "mint-refungible-token",
            "link": "#mint-refungible-token",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Fungible",
        "slug": "fungible",
        "link": "#fungible",
        "children": [
          {
            "level": 3,
            "title": "Create fungible collection",
            "slug": "create-fungible-collection",
            "link": "#create-fungible-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Mint fungible token",
            "slug": "mint-fungible-token",
            "link": "#mint-fungible-token",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Generic",
        "slug": "generic",
        "link": "#generic",
        "children": [
          {
            "level": 3,
            "title": "Make collection 721Metadata compatible",
            "slug": "make-collection-721metadata-compatible",
            "link": "#make-collection-721metadata-compatible",
            "children": []
          },
          {
            "level": 3,
            "title": "Set sponsor for collection",
            "slug": "set-sponsor-for-collection",
            "link": "#set-sponsor-for-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Transfer token",
            "slug": "transfer-token",
            "link": "#transfer-token",
            "children": []
          }
        ]
      }
    ],
    "path": "/tutorials/evm/using-sol-interfaces.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Customizable NFTs guide",
    "headers": [
      {
        "level": 2,
        "title": "🏁 1. Before we start",
        "slug": "🏁-1-before-we-start",
        "link": "#🏁-1-before-we-start",
        "children": [
          {
            "level": 3,
            "title": "1.1 Prepare images and metadata",
            "slug": "_1-1-prepare-images-and-metadata",
            "link": "#_1-1-prepare-images-and-metadata",
            "children": []
          },
          {
            "level": 3,
            "title": "1.2 If you are ready to create your own collection",
            "slug": "_1-2-if-you-are-ready-to-create-your-own-collection",
            "link": "#_1-2-if-you-are-ready-to-create-your-own-collection",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "🧸 3. Creating the Base Collection",
        "slug": "🧸-3-creating-the-base-collection",
        "link": "#🧸-3-creating-the-base-collection",
        "children": [
          {
            "level": 3,
            "title": "3.1 Prepare Collection Images and Metadata",
            "slug": "_3-1-prepare-collection-images-and-metadata",
            "link": "#_3-1-prepare-collection-images-and-metadata",
            "children": []
          },
          {
            "level": 3,
            "title": "3.2 Prepare the Configuration File",
            "slug": "_3-2-prepare-the-configuration-file",
            "link": "#_3-2-prepare-the-configuration-file",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "💎 4. Mint base collection and NFTs",
        "slug": "💎-4-mint-base-collection-and-nfts",
        "link": "#💎-4-mint-base-collection-and-nfts",
        "children": [
          {
            "level": 3,
            "title": "4.1 Upload images to IPFS",
            "slug": "_4-1-upload-images-to-ipfs",
            "link": "#_4-1-upload-images-to-ipfs",
            "children": []
          },
          {
            "level": 3,
            "title": "4.2 Create a collection",
            "slug": "_4-2-create-a-collection",
            "link": "#_4-2-create-a-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "4.3 Create NFTs",
            "slug": "_4-3-create-nfts",
            "link": "#_4-3-create-nfts",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "👕 5. Creating the Accessories Collection",
        "slug": "👕-5-creating-the-accessories-collection",
        "link": "#👕-5-creating-the-accessories-collection",
        "children": [
          {
            "level": 3,
            "title": "5.1 Prepare the Configuration File",
            "slug": "_5-1-prepare-the-configuration-file",
            "link": "#_5-1-prepare-the-configuration-file",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "💎 6. Mint accessories collection and NFTs",
        "slug": "💎-6-mint-accessories-collection-and-nfts",
        "link": "#💎-6-mint-accessories-collection-and-nfts",
        "children": [
          {
            "level": 3,
            "title": "6.1 Upload images to IPFS",
            "slug": "_6-1-upload-images-to-ipfs",
            "link": "#_6-1-upload-images-to-ipfs",
            "children": []
          },
          {
            "level": 3,
            "title": "6.2 Create a collection",
            "slug": "_6-2-create-a-collection",
            "link": "#_6-2-create-a-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "6.3 Create NFTs",
            "slug": "_6-3-create-nfts",
            "link": "#_6-3-create-nfts",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "😎 7. Check how customization works",
        "slug": "😎-7-check-how-customization-works",
        "link": "#😎-7-check-how-customization-works",
        "children": []
      }
    ],
    "path": "/tutorials/minting/customizable-nfts.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Generative NFT Guide",
    "headers": [
      {
        "level": 2,
        "title": "👩‍🎓 Who is this guide for",
        "slug": "👩‍🎓-who-is-this-guide-for",
        "link": "#👩‍🎓-who-is-this-guide-for",
        "children": []
      },
      {
        "level": 2,
        "title": "Before we start",
        "slug": "before-we-start",
        "link": "#before-we-start",
        "children": []
      },
      {
        "level": 2,
        "title": "🖼 Step-1: Prepare the images",
        "slug": "🖼-step-1-prepare-the-images",
        "link": "#🖼-step-1-prepare-the-images",
        "children": [
          {
            "level": 3,
            "title": "1.1 Design image parts",
            "slug": "_1-1-design-image-parts",
            "link": "#_1-1-design-image-parts",
            "children": []
          },
          {
            "level": 3,
            "title": "1.2 Set collection cover",
            "slug": "_1-2-set-collection-cover",
            "link": "#_1-2-set-collection-cover",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "👨‍🎨 Step-2: Describe NFT attributes",
        "slug": "👨‍🎨-step-2-describe-nft-attributes",
        "link": "#👨‍🎨-step-2-describe-nft-attributes",
        "children": [
          {
            "level": 3,
            "title": "2.1 Encode attributes",
            "slug": "_2-1-encode-attributes",
            "link": "#_2-1-encode-attributes",
            "children": []
          },
          {
            "level": 3,
            "title": "2.2 Set the collection metadata",
            "slug": "_2-2-set-the-collection-metadata",
            "link": "#_2-2-set-the-collection-metadata",
            "children": []
          },
          {
            "level": 3,
            "title": "2.3 Generate images and metadata",
            "slug": "_2-3-generate-images-and-metadata",
            "link": "#_2-3-generate-images-and-metadata",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "⛓ Step-3: Prepare Substrate Account",
        "slug": "⛓-step-3-prepare-substrate-account",
        "link": "#⛓-step-3-prepare-substrate-account",
        "children": [
          {
            "level": 3,
            "title": "3.1 Generate address and seed phrase",
            "slug": "_3-1-generate-address-and-seed-phrase",
            "link": "#_3-1-generate-address-and-seed-phrase",
            "children": []
          },
          {
            "level": 3,
            "title": "3.2 Get some tokens",
            "slug": "_3-2-get-some-tokens",
            "link": "#_3-2-get-some-tokens",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "💎 Step-4: Create Collection and NFTs",
        "slug": "💎-step-4-create-collection-and-nfts",
        "link": "#💎-step-4-create-collection-and-nfts",
        "children": [
          {
            "level": 3,
            "title": "4.1 Upload images to IPFS",
            "slug": "_4-1-upload-images-to-ipfs",
            "link": "#_4-1-upload-images-to-ipfs",
            "children": []
          },
          {
            "level": 3,
            "title": "4.2 Create a collection",
            "slug": "_4-2-create-a-collection",
            "link": "#_4-2-create-a-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "4.3 Create NFTs",
            "slug": "_4-3-create-nfts",
            "link": "#_4-3-create-nfts",
            "children": []
          }
        ]
      }
    ],
    "path": "/tutorials/minting/generative-nft.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Welcome to the minting guides section",
    "headers": [],
    "path": "/tutorials/minting/main.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Mass listing on Unique Market",
    "headers": [
      {
        "level": 2,
        "title": "⚙️ Step-1: Setting up environment",
        "slug": "⚙️-step-1-setting-up-environment",
        "link": "#⚙️-step-1-setting-up-environment",
        "children": []
      },
      {
        "level": 2,
        "title": "📄 Step-2: Get your NFTs list",
        "slug": "📄-step-2-get-your-nfts-list",
        "link": "#📄-step-2-get-your-nfts-list",
        "children": []
      },
      {
        "level": 2,
        "title": "🎁 Step-3: List your NFTs for sale",
        "slug": "🎁-step-3-list-your-nfts-for-sale",
        "link": "#🎁-step-3-list-your-nfts-for-sale",
        "children": []
      },
      {
        "level": 2,
        "title": "Delist",
        "slug": "delist",
        "link": "#delist",
        "children": []
      }
    ],
    "path": "/tutorials/minting/mass-listing.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Mass NFT minting guide",
    "headers": [
      {
        "level": 2,
        "title": "👩‍🎓 Who is this guide for",
        "slug": "👩‍🎓-who-is-this-guide-for",
        "link": "#👩‍🎓-who-is-this-guide-for",
        "children": []
      },
      {
        "level": 2,
        "title": "Before we start",
        "slug": "before-we-start",
        "link": "#before-we-start",
        "children": []
      },
      {
        "level": 2,
        "title": "🖼 Step-1: Prepare your images",
        "slug": "🖼-step-1-prepare-your-images",
        "link": "#🖼-step-1-prepare-your-images",
        "children": []
      },
      {
        "level": 2,
        "title": "📇 Step-2: Prepare collection metadata",
        "slug": "📇-step-2-prepare-collection-metadata",
        "link": "#📇-step-2-prepare-collection-metadata",
        "children": []
      },
      {
        "level": 2,
        "title": "👨‍🎨 Step-3: Describe NFT attributes",
        "slug": "👨‍🎨-step-3-describe-nft-attributes",
        "link": "#👨‍🎨-step-3-describe-nft-attributes",
        "children": []
      },
      {
        "level": 2,
        "title": "⛓ Step-4: Prepare Substrate Account",
        "slug": "⛓-step-4-prepare-substrate-account",
        "link": "#⛓-step-4-prepare-substrate-account",
        "children": [
          {
            "level": 3,
            "title": "4.1 Generate address and seed phrase",
            "slug": "_4-1-generate-address-and-seed-phrase",
            "link": "#_4-1-generate-address-and-seed-phrase",
            "children": []
          },
          {
            "level": 3,
            "title": "4.2 Get some tokens",
            "slug": "_4-2-get-some-tokens",
            "link": "#_4-2-get-some-tokens",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "💎 Step-5: Create Collection and NFTs",
        "slug": "💎-step-5-create-collection-and-nfts",
        "link": "#💎-step-5-create-collection-and-nfts",
        "children": [
          {
            "level": 3,
            "title": "5.1 Upload images to IPFS",
            "slug": "_5-1-upload-images-to-ipfs",
            "link": "#_5-1-upload-images-to-ipfs",
            "children": []
          },
          {
            "level": 3,
            "title": "5.2 Create a collection",
            "slug": "_5-2-create-a-collection",
            "link": "#_5-2-create-a-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "5.3 Create NFTs",
            "slug": "_5-3-create-nfts",
            "link": "#_5-3-create-nfts",
            "children": []
          }
        ]
      }
    ],
    "path": "/tutorials/minting/mass-minting.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Getting Account and OPL Tokens",
    "headers": [
      {
        "level": 2,
        "title": "Generate address and seed phrase",
        "slug": "generate-address-and-seed-phrase",
        "link": "#generate-address-and-seed-phrase",
        "children": []
      },
      {
        "level": 2,
        "title": "Get some tokens",
        "slug": "get-some-tokens",
        "link": "#get-some-tokens",
        "children": []
      }
    ],
    "path": "/tutorials/minting/prepare-account.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Preparing scripts",
    "headers": [
      {
        "level": 3,
        "title": "1.1 Download the project",
        "slug": "_1-1-download-the-project",
        "link": "#_1-1-download-the-project",
        "children": []
      },
      {
        "level": 3,
        "title": "1.2 Install dependencies",
        "slug": "_1-2-install-dependencies",
        "link": "#_1-2-install-dependencies",
        "children": []
      }
    ],
    "path": "/tutorials/minting/prepare-scripts.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Setting up an environment",
    "headers": [
      {
        "level": 3,
        "title": "1. Install Node.js",
        "slug": "_1-install-node-js",
        "link": "#_1-install-node-js",
        "children": []
      },
      {
        "level": 3,
        "title": "2. Install Git",
        "slug": "_2-install-git",
        "link": "#_2-install-git",
        "children": []
      },
      {
        "level": 3,
        "title": "3. Install IDE",
        "slug": "_3-install-ide",
        "link": "#_3-install-ide",
        "children": []
      }
    ],
    "path": "/tutorials/minting/setup-environment.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "How to connect Ledger to Wallet",
    "headers": [
      {
        "level": 3,
        "title": "Connect Ledger to Metamask",
        "slug": "connect-ledger-to-metamask",
        "link": "#connect-ledger-to-metamask",
        "children": []
      },
      {
        "level": 3,
        "title": "Connect Ledger to Wallet via Metamask",
        "slug": "connect-ledger-to-wallet-via-metamask",
        "link": "#connect-ledger-to-wallet-via-metamask",
        "children": []
      },
      {
        "level": 3,
        "title": "How to keep tokens in Ledger",
        "slug": "how-to-keep-tokens-in-ledger",
        "link": "#how-to-keep-tokens-in-ledger",
        "children": []
      }
    ],
    "path": "/tutorials/user-guides/ledger-connect.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Polkadot.js browser extension",
    "headers": [],
    "path": "/tutorials/user-guides/polkadotjs.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Choose your wallet",
    "headers": [],
    "path": "/tutorials/user-guides/wallets.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Collection Helpers",
    "headers": [
      {
        "level": 2,
        "title": "Code examples",
        "slug": "code-examples",
        "link": "#code-examples",
        "children": [
          {
            "level": 3,
            "title": "Connect to contract",
            "slug": "connect-to-contract",
            "link": "#connect-to-contract",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Next steps",
        "slug": "next-steps",
        "link": "#next-steps",
        "children": []
      }
    ],
    "path": "/build/evm/smart-contracts/collection-helpers.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Contract Helpers",
    "headers": [
      {
        "level": 2,
        "title": "Interface",
        "slug": "interface",
        "link": "#interface",
        "children": []
      },
      {
        "level": 2,
        "title": "Code examples",
        "slug": "code-examples",
        "link": "#code-examples",
        "children": [
          {
            "level": 3,
            "title": "Connect to contract",
            "slug": "connect-to-contract",
            "link": "#connect-to-contract",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Choose sponsoring mode",
        "slug": "choose-sponsoring-mode",
        "link": "#choose-sponsoring-mode",
        "children": [
          {
            "level": 3,
            "title": "Self-sponsoring",
            "slug": "self-sponsoring",
            "link": "#self-sponsoring",
            "children": []
          },
          {
            "level": 3,
            "title": "External address sponsoring",
            "slug": "external-address-sponsoring",
            "link": "#external-address-sponsoring",
            "children": []
          },
          {
            "level": 3,
            "title": "Enable sponsoring",
            "slug": "enable-sponsoring",
            "link": "#enable-sponsoring",
            "children": []
          }
        ]
      }
    ],
    "path": "/build/evm/smart-contracts/contract-helpers.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Intro",
    "headers": [],
    "path": "/build/evm/smart-contracts/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Writing smart contracts",
    "headers": [
      {
        "level": 2,
        "title": "Making Your Assets Compatible with the Unique Schema",
        "slug": "making-your-assets-compatible-with-the-unique-schema",
        "link": "#making-your-assets-compatible-with-the-unique-schema",
        "children": [
          {
            "level": 3,
            "title": "Creating a Collection",
            "slug": "creating-a-collection",
            "link": "#creating-a-collection",
            "children": []
          },
          {
            "level": 3,
            "title": "Adding a Token Minting Function",
            "slug": "adding-a-token-minting-function",
            "link": "#adding-a-token-minting-function",
            "children": []
          },
          {
            "level": 3,
            "title": "Final Contract",
            "slug": "final-contract",
            "link": "#final-contract",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Setting up Metamask",
        "slug": "setting-up-metamask",
        "link": "#setting-up-metamask",
        "children": []
      }
    ],
    "path": "/build/evm/smart-contracts/writing-contracts.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Balances",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisite",
        "slug": "prerequisite",
        "link": "#prerequisite",
        "children": []
      },
      {
        "level": 2,
        "title": "Get balance",
        "slug": "get-balance",
        "link": "#get-balance",
        "children": []
      },
      {
        "level": 2,
        "title": "Transfer",
        "slug": "transfer",
        "link": "#transfer",
        "children": []
      }
    ],
    "path": "/build/sdk/v2/balances.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Collections",
    "headers": [
      {
        "level": 2,
        "title": "Intro",
        "slug": "intro",
        "link": "#intro",
        "children": []
      },
      {
        "level": 2,
        "title": "Prerequisite",
        "slug": "prerequisite",
        "link": "#prerequisite",
        "children": []
      },
      {
        "level": 2,
        "title": "Creating a collection",
        "slug": "creating-a-collection",
        "link": "#creating-a-collection",
        "children": []
      },
      {
        "level": 2,
        "title": "Collection modes",
        "slug": "collection-modes",
        "link": "#collection-modes",
        "children": []
      },
      {
        "level": 2,
        "title": "Collection properties",
        "slug": "collection-properties",
        "link": "#collection-properties",
        "children": [
          {
            "level": 3,
            "title": "Setting collection properties",
            "slug": "setting-collection-properties",
            "link": "#setting-collection-properties",
            "children": []
          },
          {
            "level": 3,
            "title": "Now, let's query our collection and check its properties",
            "slug": "now-let-s-query-our-collection-and-check-its-properties",
            "link": "#now-let-s-query-our-collection-and-check-its-properties",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Token property permissions",
        "slug": "token-property-permissions",
        "link": "#token-property-permissions",
        "children": []
      },
      {
        "level": 2,
        "title": "Nesting configuration",
        "slug": "nesting-configuration",
        "link": "#nesting-configuration",
        "children": []
      },
      {
        "level": 2,
        "title": "Collection limits",
        "slug": "collection-limits",
        "link": "#collection-limits",
        "children": []
      },
      {
        "level": 2,
        "title": "Sponsoring",
        "slug": "sponsoring",
        "link": "#sponsoring",
        "children": [
          {
            "level": 3,
            "title": "Setting collection sponsor",
            "slug": "setting-collection-sponsor",
            "link": "#setting-collection-sponsor",
            "children": []
          }
        ]
      }
    ],
    "path": "/build/sdk/v2/collections.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Building DApps",
    "headers": [
      {
        "level": 2,
        "title": "Quick Start",
        "slug": "quick-start",
        "link": "#quick-start",
        "children": []
      },
      {
        "level": 2,
        "title": "Working with the Unique SDK",
        "slug": "working-with-the-unique-sdk",
        "link": "#working-with-the-unique-sdk",
        "children": [
          {
            "level": 3,
            "title": "SDK Overview",
            "slug": "sdk-overview",
            "link": "#sdk-overview",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Connecting Accounts",
        "slug": "connecting-accounts",
        "link": "#connecting-accounts",
        "children": [
          {
            "level": 3,
            "title": "Connecting Polkadot wallets",
            "slug": "connecting-polkadot-wallets",
            "link": "#connecting-polkadot-wallets",
            "children": []
          },
          {
            "level": 3,
            "title": "Using Connected Account with SDK",
            "slug": "using-connected-account-with-sdk",
            "link": "#using-connected-account-with-sdk",
            "children": []
          }
        ]
      }
    ],
    "path": "/build/sdk/v2/dapps.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "EVM and Smart Contracts",
    "headers": [
      {
        "level": 2,
        "title": "How does it work",
        "slug": "how-does-it-work",
        "link": "#how-does-it-work",
        "children": []
      },
      {
        "level": 2,
        "title": "Code examples",
        "slug": "code-examples",
        "link": "#code-examples",
        "children": []
      }
    ],
    "path": "/build/sdk/v2/evm.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique SDK indexer",
    "headers": [
      {
        "level": 2,
        "title": "API Endpoints",
        "slug": "api-endpoints",
        "link": "#api-endpoints",
        "children": []
      },
      {
        "level": 2,
        "title": "SDK Initialization",
        "slug": "sdk-initialization",
        "link": "#sdk-initialization",
        "children": []
      },
      {
        "level": 2,
        "title": "Examples",
        "slug": "examples",
        "link": "#examples",
        "children": []
      },
      {
        "level": 2,
        "title": "Use Cases and Code Samples",
        "slug": "use-cases-and-code-samples",
        "link": "#use-cases-and-code-samples",
        "children": [
          {
            "level": 3,
            "title": "Fetch the Last 5 Blocks",
            "slug": "fetch-the-last-5-blocks",
            "link": "#fetch-the-last-5-blocks",
            "children": []
          },
          {
            "level": 3,
            "title": "Fetch the First 5 Blocks of the Current Year",
            "slug": "fetch-the-first-5-blocks-of-the-current-year",
            "link": "#fetch-the-first-5-blocks-of-the-current-year",
            "children": []
          },
          {
            "level": 3,
            "title": "Fetch a Single Block by Hash",
            "slug": "fetch-a-single-block-by-hash",
            "link": "#fetch-a-single-block-by-hash",
            "children": []
          },
          {
            "level": 3,
            "title": "Fetch a Single Block by Number",
            "slug": "fetch-a-single-block-by-number",
            "link": "#fetch-a-single-block-by-number",
            "children": []
          },
          {
            "level": 3,
            "title": "Fetch All Extrinsic Sections and Methods",
            "slug": "fetch-all-extrinsic-sections-and-methods",
            "link": "#fetch-all-extrinsic-sections-and-methods",
            "children": []
          },
          {
            "level": 3,
            "title": "Fetch Extrinsics by Section and Method",
            "slug": "fetch-extrinsics-by-section-and-method",
            "link": "#fetch-extrinsics-by-section-and-method",
            "children": []
          },
          {
            "level": 3,
            "title": "Fetch Extrinsics by Signer",
            "slug": "fetch-extrinsics-by-signer",
            "link": "#fetch-extrinsics-by-signer",
            "children": []
          },
          {
            "level": 3,
            "title": "Search Collections",
            "slug": "search-collections",
            "link": "#search-collections",
            "children": []
          },
          {
            "level": 3,
            "title": "Search NFTs",
            "slug": "search-nfts",
            "link": "#search-nfts",
            "children": []
          },
          {
            "level": 3,
            "title": "Search Accounts",
            "slug": "search-accounts",
            "link": "#search-accounts",
            "children": []
          },
          {
            "level": 3,
            "title": "Fetch Event Sections",
            "slug": "fetch-event-sections",
            "link": "#fetch-event-sections",
            "children": []
          },
          {
            "level": 3,
            "title": "Search Events",
            "slug": "search-events",
            "link": "#search-events",
            "children": []
          }
        ]
      }
    ],
    "path": "/build/sdk/v2/indexer.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Migration to SDK-2.0",
    "headers": [
      {
        "level": 2,
        "title": "Working with Balances",
        "slug": "working-with-balances",
        "link": "#working-with-balances",
        "children": []
      },
      {
        "level": 2,
        "title": "Working with Collections and Tokens",
        "slug": "working-with-collections-and-tokens",
        "link": "#working-with-collections-and-tokens",
        "children": [
          {
            "level": 3,
            "title": "Reading Collections",
            "slug": "reading-collections",
            "link": "#reading-collections",
            "children": []
          },
          {
            "level": 3,
            "title": "Reading Tokens",
            "slug": "reading-tokens",
            "link": "#reading-tokens",
            "children": []
          },
          {
            "level": 3,
            "title": "Extrinsics",
            "slug": "extrinsics",
            "link": "#extrinsics",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Application Creates Tokens in the V1 Collection",
        "slug": "application-creates-tokens-in-the-v1-collection",
        "link": "#application-creates-tokens-in-the-v1-collection",
        "children": []
      }
    ],
    "path": "/build/sdk/v2/migration.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Unique SDK quick start",
    "headers": [
      {
        "level": 2,
        "title": "Getting started",
        "slug": "getting-started",
        "link": "#getting-started",
        "children": [
          {
            "level": 3,
            "title": "Installation",
            "slug": "installation",
            "link": "#installation",
            "children": []
          },
          {
            "level": 3,
            "title": "Import and initialize the SDK",
            "slug": "import-and-initialize-the-sdk",
            "link": "#import-and-initialize-the-sdk",
            "children": []
          },
          {
            "level": 3,
            "title": "Make some requests",
            "slug": "make-some-requests",
            "link": "#make-some-requests",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Run your own HTTP proxy",
        "slug": "run-your-own-http-proxy",
        "link": "#run-your-own-http-proxy",
        "children": []
      }
    ],
    "path": "/build/sdk/v2/quick-start.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Collection sponsoring",
    "headers": [
      {
        "level": 2,
        "title": "Prerequisite",
        "slug": "prerequisite",
        "link": "#prerequisite",
        "children": []
      },
      {
        "level": 2,
        "title": "Setting collection sponsoring",
        "slug": "setting-collection-sponsoring",
        "link": "#setting-collection-sponsoring",
        "children": []
      },
      {
        "level": 2,
        "title": "Setting limits",
        "slug": "setting-limits",
        "link": "#setting-limits",
        "children": []
      },
      {
        "level": 2,
        "title": "Let's check that an account without native tokens can transfer NFT",
        "slug": "let-s-check-that-an-account-without-native-tokens-can-transfer-nft",
        "link": "#let-s-check-that-an-account-without-native-tokens-can-transfer-nft",
        "children": []
      }
    ],
    "path": "/build/sdk/v2/sponsoring.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "NFTs",
    "headers": [
      {
        "level": 2,
        "title": "Intro",
        "slug": "intro",
        "link": "#intro",
        "children": []
      },
      {
        "level": 2,
        "title": "Prerequisite",
        "slug": "prerequisite",
        "link": "#prerequisite",
        "children": []
      },
      {
        "level": 2,
        "title": "Getting started",
        "slug": "getting-started",
        "link": "#getting-started",
        "children": []
      },
      {
        "level": 2,
        "title": "Token properties and attributes",
        "slug": "token-properties-and-attributes",
        "link": "#token-properties-and-attributes",
        "children": [
          {
            "level": 3,
            "title": "Properties",
            "slug": "properties",
            "link": "#properties",
            "children": []
          },
          {
            "level": 3,
            "title": "Attributes",
            "slug": "attributes",
            "link": "#attributes",
            "children": []
          },
          {
            "level": 3,
            "title": "Properties and attributes mutation",
            "slug": "properties-and-attributes-mutation",
            "link": "#properties-and-attributes-mutation",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "Transfer",
        "slug": "transfer",
        "link": "#transfer",
        "children": []
      },
      {
        "level": 2,
        "title": "Burn",
        "slug": "burn",
        "link": "#burn",
        "children": []
      },
      {
        "level": 2,
        "title": "Nesting",
        "slug": "nesting",
        "link": "#nesting",
        "children": []
      }
    ],
    "path": "/build/sdk/v2/tokens.html",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Addresses: Substrate & EVM",
    "headers": [
      {
        "level": 3,
        "title": "Substrate addresses",
        "slug": "substrate-addresses",
        "link": "#substrate-addresses",
        "children": []
      },
      {
        "level": 3,
        "title": "Ethereum addresses",
        "slug": "ethereum-addresses",
        "link": "#ethereum-addresses",
        "children": []
      },
      {
        "level": 2,
        "title": "Address Mirroring",
        "slug": "address-mirroring",
        "link": "#address-mirroring",
        "children": []
      },
      {
        "level": 2,
        "title": "Live address encoder",
        "slug": "live-address-encoder",
        "link": "#live-address-encoder",
        "children": []
      }
    ],
    "path": "/build/tech-concepts/addresses/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "Balances",
    "headers": [
      {
        "level": 2,
        "title": "Total",
        "slug": "total",
        "link": "#total",
        "children": []
      },
      {
        "level": 2,
        "title": "1. Free",
        "slug": "_1-free",
        "link": "#_1-free",
        "children": [
          {
            "level": 3,
            "title": "1.1 Transferable",
            "slug": "_1-1-transferable",
            "link": "#_1-1-transferable",
            "children": []
          },
          {
            "level": 3,
            "title": "1.2 Frozen",
            "slug": "_1-2-frozen",
            "link": "#_1-2-frozen",
            "children": []
          }
        ]
      },
      {
        "level": 2,
        "title": "2. Reserved",
        "slug": "_2-reserved",
        "link": "#_2-reserved",
        "children": []
      },
      {
        "level": 2,
        "title": "Cases",
        "slug": "cases",
        "link": "#cases",
        "children": [
          {
            "level": 3,
            "title": "1. Claim of vested tokens",
            "slug": "_1-claim-of-vested-tokens",
            "link": "#_1-claim-of-vested-tokens",
            "children": []
          },
          {
            "level": 3,
            "title": "2. Vested tokens staking",
            "slug": "_2-vested-tokens-staking",
            "link": "#_2-vested-tokens-staking",
            "children": []
          },
          {
            "level": 3,
            "title": "3. Staking reward payout",
            "slug": "_3-staking-reward-payout",
            "link": "#_3-staking-reward-payout",
            "children": []
          },
          {
            "level": 3,
            "title": "4. Reserving balances",
            "slug": "_4-reserving-balances",
            "link": "#_4-reserving-balances",
            "children": []
          }
        ]
      }
    ],
    "path": "/build/tech-concepts/balances/",
    "pathLocale": "/",
    "extraFields": []
  },
  {
    "title": "",
    "headers": [],
    "path": "/404.html",
    "pathLocale": "/",
    "extraFields": []
  }
]
